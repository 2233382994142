/**
 * @param {string} xurl
 * @param {AbortSignal|null} [signal]
 * @return {Promise<null|any>}
 */
export const getXurl = async (xurl, { signal } = {}) => {
  if (!xurl) return null
  const response = await fetch(xurl, {
    signal,
    withCredentials: false,
  })
  if (!response.ok) {
    throw new Error(
      `Network error ${response.status}${response.statusText ? ' ' + response.statusText : ''} fetching ${xurl}`
    )
  }
  return await response.json()
}
