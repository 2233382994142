/**
 * @typedef {'1 week'|'1 month'|'3 months'|'6 months'|'1 year'} PERIOD_OPTION
 */
/**
 * @typedef {'custom'} CUSTOM_PERIOD_OPTION
 */
/**
 * @typedef {PERIOD_OPTION | CUSTOM_PERIOD_OPTION} FULL_PERIOD_OPTION
 */
/**
 * @typedef {{begin_time: string|PERIOD_OPTION, end_time: string}} PACKED_PERIOD
 */
/**
 * @typedef {{option: FULL_PERIOD_OPTION|null, begin_time: string, end_time: string}} UNPACKED_PERIOD
 */

/**
 * @type {CUSTOM_PERIOD_OPTION}
 */
export const CUSTOM_OPTION = 'custom'

/**
 * @type {ALL_TIME_OPTION}
 */
export const ALL_TIME_OPTION = 'alltime'

/**
 * @type {PACKED_PERIOD}
 */
export const DEFAULT_PACKED = {
  begin_time: '',
  end_time: '',
}

/**
 * @type {UNPACKED_PERIOD}
 */
export const DEFAULT_UNPACKED = {
  ...DEFAULT_PACKED,
}

/**
 * @type {Record<PERIOD_OPTION, number>}
 */
const TIME_OFFSETS = {
  '1 week': 7 * 24 * 60 * 60 * 1000,
  '1 month': 30 * 24 * 60 * 60 * 1000,
  '3 months': 91 * 24 * 60 * 60 * 1000,
  '6 months': 182 * 24 * 60 * 60 * 1000,
  '1 year': 365 * 24 * 60 * 60 * 1000,
}

/**
 *
 * @param {UNPACKED_PERIOD} unpackedPeriod
 * @returns {PACKED_PERIOD}
 */
export function packPeriod(unpackedPeriod) {
  if (unpackedPeriod.option === CUSTOM_OPTION) {
    return {
      begin_time: unpackedPeriod.begin_time || CUSTOM_OPTION,
      end_time: unpackedPeriod.end_time,
    }
  }
  return {
    begin_time: unpackedPeriod.option,
    end_time: '',
  }
}

/**
 * @param {PACKED_PERIOD} packedPeriod
 * @returns {UNPACKED_PERIOD}
 */
export function unpackPeriod(packedPeriod) {
  if (!packedPeriod || (!packedPeriod.begin_time && !packedPeriod.end_time)) {
    return DEFAULT_UNPACKED
  }
  if (!packedPeriod.end_time && packedPeriod.begin_time in TIME_OFFSETS) {
    return {
      option: packedPeriod.begin_time,
      begin_time: '',
      end_time: '',
    }
  }
  if (!packedPeriod.end_time && packedPeriod.begin_time === ALL_TIME_OPTION) {
    return {
      option: ALL_TIME_OPTION,
      begin_time: '',
      end_time: '',
    }
  }
  return {
    option: CUSTOM_OPTION,
    begin_time: packedPeriod.begin_time !== CUSTOM_OPTION ? packedPeriod.begin_time : '',
    end_time: packedPeriod.end_time,
  }
}

export function dateToApi(date) {
  if (!date) {
    return null
  }

  if (typeof date === 'number') {
    date = new Date(date)
  } else if (typeof date === 'string') {
    date = new Date(Date.parse(date))
  } else if (!(date instanceof Date)) {
    throw new Error(`unhandled date type ${typeof date}: ${date}`)
  }
  return date.toISOString()
}

/**
 * @param {PACKED_PERIOD|UNPACKED_PERIOD} period
 * @returns {PACKED_PERIOD|null}
 */
export function periodToApi(period) {
  if (!period) return null
  const unpacked = period.option ? period : unpackPeriod(period)
  if (unpacked.option === CUSTOM_OPTION) {
    return {
      created_gte: dateToApi(unpacked.begin_time),
      created_lte: dateToApi(unpacked.end_time),
    }
  }
  if (unpacked.option in TIME_OFFSETS) {
    return {
      created_gte: dateToApi(Date.now() - TIME_OFFSETS[unpacked.option]),
      created_lte: null,
    }
  }
  return DEFAULT_PACKED
}
