export const convertMinimapData = (minimapData, players, teams, agentsList) => {
  if (!minimapData) {
    return null
  }

  const { version } = minimapData
  if (!minimapData.version || version === '1.0') {
    return minimapData
  } else if (version === '2.0') {
    return convertMinimapDataV2(minimapData, players, teams, agentsList)
  }
}

const repeatEvent = ({ t, d, data, interval = 100 }) => {
  const list = []
  const time = t * 1000
  const duration = d * 1000
  for (let offset = 0; offset < duration; offset += interval) {
    list.push({
      ...data,
      round_time_millis: time + offset,
    })
  }
  list.push({
    ...data,
    round_time_millis: time + duration,
  })
  return list
}

const convertMinimapDataV2 = (minimapData, players, teams, agentsList) => {
  if (!minimapData) {
    return null
  }

  const resultMinimapData = {
    version: '1.0',
    ability_locations: [],
    player_locations: [],
    smoke_locations: [],
    spike_locations: [],
    wall_vectors: [],
  }

  Object.entries(minimapData.data?.ability_placements || {}).forEach(([identifier, positions]) => {
    const [agentName, slot, role] = identifier.split('-')
    const agentId = agentsList.find(agent => agent.mlname === agentName)?.id
    const puuid = players.find(
      player => player.agent === agentId && player.team_side === teams.find(team => team.role === role)?.grid
    )?.puuid

    positions.forEach((position, index) => {
      Array.prototype.push.apply(
        resultMinimapData.ability_locations,
        repeatEvent({
          ...position,
          data: {
            location: {
              x: position.xy[0],
              y: position.xy[1],
            },
            puuid,
            slot,
            track_id: `ability-${identifier}-${index}`,
          },
        })
      )
    })
  })

  Object.entries(minimapData.data?.ability_tracks || {}).forEach(([identifier, tracks]) => {
    const [agentName, slot, role] = identifier.split('-')
    const puuid = calcPuuidForAgentName(agentName, role, players, teams, agentsList)

    tracks.forEach((positions, index) => {
      positions.forEach(position => {
        resultMinimapData.ability_locations.push({
          location: {
            x: position.xy[0],
            y: position.xy[1],
          },
          puuid,
          round_time_millis: position.t * 1000,
          slot,
          track_id: `ability-${identifier}-${index}`,
        })
      })
    })
  })

  Object.entries(minimapData.data?.agent_tracks || {}).forEach(([identifier, tracks]) => {
    const [agentName, role] = identifier.split('-')
    const puuid = calcPuuidForAgentName(agentName, role, players, teams, agentsList)

    tracks.forEach((positions, index) => {
      positions.forEach(position => {
        resultMinimapData.player_locations.push({
          location: {
            x: position.xy[0],
            y: position.xy[1],
          },
          puuid,
          round_time_millis: position.t * 1000,
          track_id: `agent-${identifier}-${index}`,
        })
      })
    })
  })

  Object.entries(minimapData.data?.smoke_placements || {}).forEach(([, positions]) => {
    positions.forEach((position, index) => {
      Array.prototype.push.apply(
        resultMinimapData.smoke_locations,
        repeatEvent({
          ...position,
          data: {
            location: {
              x: position.xywh[0],
              y: position.xywh[1],
            },
            radius: (position.xywh[2] + position.xywh[3]) / 4,
            track_id: `smoke-${index}`,
          },
        })
      )
    })
  })

  Object.entries(minimapData.data?.wall_placements || {}).forEach(([identifier, positions]) => {
    const [, role] = identifier.split('-')
    positions.forEach((position, index) => {
      Array.prototype.push.apply(
        resultMinimapData.wall_vectors,
        repeatEvent({
          ...position,
          data: {
            location: {
              x: position.xyxy[0],
              y: position.xyxy[1],
            },
            vector: [
              { x: position.xyxy[0], y: position.xyxy[1] },
              { x: position.xyxy[2], y: position.xyxy[3] },
            ],
            role: role,
            track_id: `wall-${index}`,
          },
        })
      )
    })
  })

  return resultMinimapData
}

const calcPuuidForAgentName = (agentName, role, players, teams, agentsList) => {
  const agentId = agentsList.find(agent => agent.mlname === agentName)?.id
  return players.find(
    player => player.agent === agentId && player.team_side === teams.find(team => team.role === role)?.grid
  )?.puuid
}
