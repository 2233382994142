export const DEFAULT_PAGE_INDEX = 1
export const DEFAULT_ITEMS_PER_PAGE = 10
export const ESPORTS_REGION_VALUE = 'esports'

export const DEFAULT_FILTERS = Object.freeze({
  teams: [],
  teams_option: 'or',
  agent_ids: [],
  players: [],
  tournaments: '',
  map_ids: [],
  platforms: [],
  events: [],
  begin_time: '1 month',
  end_time: '',
  economy: 0,
  atk_def: 'both',
  has_vod: false,
  index: DEFAULT_PAGE_INDEX,
  count: DEFAULT_ITEMS_PER_PAGE,
})

export const ALL_RESULTS_FILTERS = Object.freeze({
  teams: [],
  teams_option: 'or',
  agent_ids: [],
  players: [],
  tournaments: '',
  map_ids: [],
  platforms: [],
  events: [],
  begin_time: 'alltime',
  end_time: '',
  economy: 0,
  atk_def: 'both',
  has_vod: false,
  index: DEFAULT_PAGE_INDEX,
  count: DEFAULT_ITEMS_PER_PAGE,
})

export default {
  namespaced: true,

  state: {
    filters: DEFAULT_FILTERS,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  },

  mutations: {
    RESET_FILTERS: state => (state.filters = DEFAULT_FILTERS),
    SET_FILTERS: (state, filters) =>
      (state.filters = Object.freeze({
        ...state.filters,
        ...filters,
      })),

    SET_ITEMS_PER_PAGE: (state, data) => (state.itemsPerPage = data),
  },

  getters: {
    server_error: state => state.server_error,

    options_platforms: state => state.options.options_platforms,
    options_economy: state => state.options.options_economy,
    options_atk_def: state => state.options.options_atk_def,

    matchSearchFilters: ({
      filters: { teams, teams_option, agent_ids, map_ids, players, platforms, events, begin_time, end_time, has_vod },
    }) =>
      Object.freeze({
        teams,
        teams_option,
        agent_ids,
        map_ids,
        players,
        platforms,
        events,
        begin_time,
        end_time,
        has_vod,
      }),
    scrimSearchFilters: ({
      filters: { title, map_ids, agent_ids, agent_option, agent_vs_ids, agent_vs_option, begin_time, end_time },
    }) =>
      Object.freeze({
        title,
        map_ids,
        agent_ids,
        agent_option,
        agent_vs_ids,
        agent_vs_option,
        begin_time,
        end_time,
      }),
    teamMatchSearchFilters: ({ filters: { teams_vs, platforms, begin_time, end_time, has_vod } }) =>
      Object.freeze({
        teams_vs,
        platforms,
        begin_time,
        end_time,
        has_vod,
      }),
    playerMatchSearchFilters: ({ filters: { begin_time, end_time, has_vod } }) =>
      Object.freeze({
        begin_time,
        end_time,
        has_vod,
      }),

    matches: state => state.matches,
    matches_total: state => state.matches_total,
    matches_loading: state => state.matches_loading,
    itemsPerPage: state => state.itemsPerPage,
  },
}
