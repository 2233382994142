export function is_empty_object(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

// Sort "teams" like arrays by `score` prop
export const sort_by_score = (team1, team2) => team2['score'] - team1['score']

export const format_time = miliseconds => {
  const seconds = `${Math.floor((miliseconds / 1000) % 60)}`
  const minutes = `${Math.floor(miliseconds / 1000 / 60)}`

  return `${minutes.length === 1 ? '0' + minutes : minutes}:${seconds.length === 1 ? '0' + seconds : seconds}`
}

export const sort_alphabetically_asc = (a, b) => {
  if (a.name > b.name) {
    return 1
  }
  if (b.name > a.name) {
    return -1
  }
  return 0
}

export const duration_str_to_seconds = str => {
  if (!str) return -1
  const splitted = str.split(':')
  if (splitted.length != 3) return -1
  let totalSeconds = 0
  let multiplier = 3600
  for (const nums of splitted) {
    const parsed = parseInt(nums)
    if (isNaN(parsed)) return -1
    if (parsed > 59 || parsed < 0) return -1
    totalSeconds += parsed * multiplier
    multiplier /= 60
  }
  return totalSeconds
}

/**
 * @template T
 * @param {T} object
 * @return {T}
 */
export const deepFreeze = object => {
  if (object == null) return object
  if (Object.isFrozen(object)) return object

  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object)

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name]

    if (value && typeof value === 'object') {
      deepFreeze(value)
    }
  }

  return Object.freeze(object)
}
