import identity from './identity.js'

/**
 * @param {String|String[]|Function|{
 *   [picker]: Function,
 *   [locales]: string|string[],
 *   [options]: Intl.CollatorOptions
 * }} opts
 * @return {function(*, *): number}
 */
export default function stringCompare(opts) {
  if (opts == null) {
    opts = {}
  } else if (typeof opts === 'function') {
    opts = { picker: opts }
  } else if (typeof opts === 'string' || Array.isArray(opts)) {
    opts = { locales: opts }
  } else if (typeof opts !== 'object') {
    throw new Error(`Unsupported opts fro stringCompareBy: ${typeof opts}`)
  }
  opts = {
    picker: identity,
    ...opts,
    options: {
      numeric: true,
      sensitivity: 'base',
      ...opts?.options,
    },
  }
  return (a, b) => String(opts.picker(a)).localeCompare(String(opts.picker(b)), opts.locales, opts.options)
}
