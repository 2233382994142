import storage from '@/store/storage'

export const getSessionKeyUsername = () => {
  const sessionKey = storage.getItem('SESSION_KEY')
  if (!sessionKey) return null
  return sessionKey.split('.', 2).pop()
}

export const hasSessionKey = () => !!storage.getItem('SESSION_KEY')

export const sessionKeyExpired = () => {
  let expired = true
  try {
    const sessionKey = storage.getItem('SESSION_KEY')
    if (!sessionKey) return true
    const [, , expiresStr] = sessionKey.split('.')
    if (!expiresStr) return true
    const expires = parseInt(expiresStr, 10)
    console.log('session expires', new Date(expires * 1000))
    expired = expires - Date.now() / 1000 < 2 * 60 * 60
  } finally {
    if (expired) {
      storage.removeItem('SESSION_KEY')
    }
  }
  return expired
}

export const ssoUrl = (action = 'login', query) =>
  `https://sso.augment.gg/${action}?${new URLSearchParams({
    redirectUrl: query?.redirect ? new URL(query.redirect, location.href) : location.href,
    ...query,
  })}`

export const redirectToSSO = (action, query) => {
  location.replace(ssoUrl(action, query))
}

export const clearSessionKey = () => {
  storage.removeItem('SESSION_KEY')
}
