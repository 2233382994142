import axios from '../../axios.js'

/**
 *
 * @param {string} matchId
 * @param cancelToken
 * @return match advanced data
 */
export const getMatchAdvancedData = async ({ matchId, isCollegiate = false }, { cancelToken } = {}) => {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const response = await axios.get(`/val/v3/${type}/matches/${matchId}/data/advanced`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} matchId
 * @param cancelToken
 * @return match advanced data
 */
export const getMatchRoundAdvancedData = async ({ matchId, roundNum, isCollegiate = false }, { cancelToken } = {}) => {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const response = await axios.get(`/val/v3/${type}/matches/${matchId}/data/advanced/rounds/${roundNum}`, {
    cancelToken,
  })
  return response.data
}

/**
 *
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<API_MATCH_ANALYTICS_INFO>}
 */
// TODO: remove since not used anymore
export const getMatchAnalyticsPositions = async (matchId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/matches/${matchId}/minimaps/positions`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<string[]>}
 */
// TODO: remove since not used anymore
export const getMatchDetectedPositions = async (matchId, { cancelToken } = {}) => {
  const responses = []
  const response = await axios.get(`/val/v2/matches/${matchId}/minimaps/detections`, { cancelToken })
  response.data.forEach(({ round_num, xurl }) => {
    responses[round_num] = xurl
  })
  return responses
}

/**
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<String>}
 */
// TODO: remove since not used anymore
export const getMatchDetectedOrbs = async (matchId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/matches/${matchId}/utilities/detections`, { cancelToken })
  return response.data.xurl
}
