const calcUtilitiesUsage = roundData => {
  if (!roundData) return []
  const utilitiesUsage = []

  // sort abilities by round_time_millis and filter out abilities with null counts
  const sortedAbilities = [...(roundData?.sidebar_detections_expand?.ability_counts || [])]
    .sort((a, b) => a.round_time_millis - b.round_time_millis)
    .filter(
      ability => ability.ability1_count !== null && ability.ability2_count !== null && ability.grenade_count !== null
    )
  const sortedUltimates = [...(roundData?.sidebar_detections_expand?.ultimate_counts || [])]
    .sort((a, b) => a.round_time_millis - b.round_time_millis)
    .filter(ultimate => ultimate.count !== null && ultimate.max_count)

  // group abilities by puuid and filter by ability count changes
  const playerAbilities = {}
  sortedAbilities.forEach(abilitiesCount => {
    if (!playerAbilities[abilitiesCount.puuid]) {
      playerAbilities[abilitiesCount.puuid] = []
      playerAbilities[abilitiesCount.puuid].push(abilitiesCount)
    } else {
      const lastCounts = playerAbilities[abilitiesCount.puuid][playerAbilities[abilitiesCount.puuid].length - 1]
      if (
        abilitiesCount.ability1_count !== lastCounts.ability1_count ||
        abilitiesCount.ability2_count !== lastCounts.ability2_count ||
        abilitiesCount.grenade_count !== lastCounts.grenade_count
      ) {
        playerAbilities[abilitiesCount.puuid].push(abilitiesCount)
      }
    }
  })

  // group ultimates by puuid and filter by ultimate count changes
  const playerUltimates = {}
  sortedUltimates.forEach(ultimateCount => {
    if (!playerUltimates[ultimateCount.puuid]) {
      playerUltimates[ultimateCount.puuid] = []
      playerUltimates[ultimateCount.puuid].push(ultimateCount)
    } else {
      const lastCounts = playerUltimates[ultimateCount.puuid][playerUltimates[ultimateCount.puuid].length - 1]
      if (ultimateCount.count !== lastCounts.count || ultimateCount.max_count !== lastCounts.max_count) {
        playerUltimates[ultimateCount.puuid].push(ultimateCount)
      }
    }
  })

  Object.entries(playerAbilities).forEach(([, counts]) => {
    counts.forEach((count, idx) => {
      // skip first ability count since we don't have previous ability count to compare
      if (idx === 0) return

      const lastCount = counts[idx - 1]
      if (count.ability1_count !== lastCount.ability1_count) {
        utilitiesUsage.push({
          round_time_millis: count.round_time_millis,
          puuid: count.puuid,
          utility: 'ability1',
          count: count.ability1_count,
          oldCount: lastCount.ability1_count,
        })
      }
      if (count.ability2_count !== lastCount.ability2_count) {
        utilitiesUsage.push({
          round_time_millis: count.round_time_millis,
          puuid: count.puuid,
          utility: 'ability2',
          count: count.ability2_count,
          oldCount: lastCount.ability2_count,
        })
      }
      if (count.grenade_count !== lastCount.grenade_count) {
        utilitiesUsage.push({
          round_time_millis: count.round_time_millis,
          puuid: count.puuid,
          utility: 'grenade',
          count: count.grenade_count,
          oldCount: lastCount.grenade_count,
        })
      }
    })
  })

  Object.entries(playerUltimates).forEach(([, counts]) => {
    counts.forEach((count, idx) => {
      // skip first ultimate count since we don't have previous ultimate count to compare
      if (idx === 0) return

      const lastCount = counts[idx - 1]
      utilitiesUsage.push({
        round_time_millis: count.round_time_millis,
        puuid: count.puuid,
        utility: 'ultimate',
        count: count.count,
        oldCount: lastCount.count,
        maxCount: count.max_count,
        oldMaXCount: lastCount.max_count,
      })
    })
  })

  return utilitiesUsage
}

export default calcUtilitiesUsage
