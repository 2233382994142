import * as Sentry from '@sentry/vue'

import axios from '@/axios.js'
import mixpanel from '@/mixpanel.js'
import { getSessionKeyUsername, hasSessionKey } from '@/utils/sso'

import storage from '../storage.js'

export default {
  namespaced: true,

  state: {
    access_tier: null,
    server_error: null,
    user_id: null,
    username: null,
    email: null,
    email_verified: false,
    is_authenticated: false,
    quotas: {},
    persistent_data: {},
  },

  mutations: {
    QUOTAS(state, quotas) {
      state.quotas = quotas || {}
    },
    USER(state, data) {
      state.access_tier = data.access_tier
      state.username = data.username
      state.user_id = data.id
      state.email = data.email
      state.email_verified = data.email_verified
      state.is_authenticated = data.is_authenticated

      if (data.is_authenticated) {
        Sentry.setUser({
          ...data,
          segment: data.access_tier,
          sso: getSessionKeyUsername(),
        })
      } else {
        Sentry.configureScope(scope => scope.setUser(null))
      }

      if (hasSessionKey()) {
        Sentry.setExtra('ssoUsername', getSessionKeyUsername())
        mixpanel.register('ssoUsername', getSessionKeyUsername())
      }
    },

    ERROR: (state, message) => (state.server_error = message),

    PERSISTENT_DATA: (state, data) => (state.persistent_data = data),

    GRID_KEY: (state, gridKey) => {
      if (!state.persistent_data) {
        state.persistent_data = {}
      }
      state.persistent_data.grid_key = gridKey
    },
  },

  actions: {
    init_from_sso(_, { sessionKey }) {
      if (sessionKey) {
        storage.setItem('SESSION_KEY', sessionKey)
      }
    },

    async init_store({ commit }) {
      try {
        commit('ERROR', null)
        const [userInfoResponse, quotasResponse, persistentDataResponse] = await Promise.all([
          axios.get('/users/v2/me'),
          // because the quotas proxy is different from the main one we need to use relative path
          axios.get('/../sso/quotas'),
          axios.get('/users/v2/persistent-data'),
        ])

        commit('USER', userInfoResponse.data)
        commit('QUOTAS', quotasResponse.data)
        commit('PERSISTENT_DATA', persistentDataResponse.data)

        if (userInfoResponse.data?.is_authenticated) {
          mixpanel.identify(userInfoResponse.data)
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          return
        }
        console.error('Failure auth/init_store()', axios.extractErrorMessage(error), error)
        commit('ERROR', axios.extractErrorMessage(error))
        Sentry.captureException(error)
      }
    },

    async login({ commit }, payload) {
      commit('ERROR', null)

      try {
        const response = await axios.post('/users/v2/login', payload)
        commit('USER', response.data)

        if (response.data.is_authenticated) {
          mixpanel.identify(response.data)
        }
        mixpanel.track_auth('logged in', response.data)

        storage.setItem('AUTH_TOKEN', response.headers['x-augment-session-token'])

        return response.data.is_authenticated
      } catch (error) {
        console.error('Failure auth/login()', axios.extractErrorMessage(error), error)
        commit('ERROR', axios.extractErrorMessage(error))
        Sentry.captureException(error)
        return false
      }
    },

    async logout({ commit }) {
      commit('ERROR', null)

      try {
        commit('USER', { is_authenticated: false })

        storage.removeItem('AUTH_TOKEN')
        storage.removeItem('SESSION_KEY')

        mixpanel.track_auth('logged out')
        mixpanel.reset()

        return false
      } catch (error) {
        console.error('Failure auth/logout()', axios.extractErrorMessage(error), error)
        commit('ERROR', axios.extractErrorMessage(error))
        Sentry.captureException(error)
      }
    },

    async register({ commit }, credentials) {
      commit('ERROR', null)

      try {
        const response = await axios.post('/users/v2/register', credentials)
        commit('USER', response.data)

        mixpanel.identify(response.data)
        mixpanel.track_auth('signed up', response.data)

        storage.setItem('AUTH_TOKEN', response.headers['x-augment-session-token'])

        return response.data.is_authenticated
      } catch (error) {
        console.error('Failure auth/register()', axios.extractErrorMessage(error), error)
        commit('ERROR', axios.extractErrorMessage(error))
        Sentry.captureException(error)
        return false
      }
    },

    verify_email({ commit }, code) {
      commit('ERROR', null)

      return axios
        .put('/users/v2/email/verify', { code })
        .then(() => {
          mixpanel.track_auth('verify email')

          return true
        })
        .catch(error => {
          console.error('Failure auth/verify_email()', axios.extractErrorMessage(error), error)
          commit('ERROR', axios.extractErrorMessage(error))
          Sentry.captureException(error)
          return false
        })
    },

    recover_password({ commit }, payload) {
      commit('ERROR', null)

      return axios
        .post('/users/v2/password/reset', payload)
        .then(() => {
          mixpanel.track_auth('recover password')

          return true
        })
        .catch(error => {
          console.error('Failure auth/recover_password()', axios.extractErrorMessage(error), error)
          commit('ERROR', axios.extractErrorMessage(error))
          Sentry.captureException(error)
          return false
        })
    },

    reset_password({ commit }, payload) {
      commit('ERROR', null)

      return axios
        .put('/users/v2/password/reset', payload)
        .then(() => {
          mixpanel.track_auth('reset password')

          return true
        })
        .catch(error => {
          console.error('Failure auth/reset_password()', axios.extractErrorMessage(error), error)
          commit('ERROR', axios.extractErrorMessage(error))
          Sentry.captureException(error)
          return false
        })
    },

    change_email({ commit }) {
      commit('ERROR', null)

      return axios
        .post('/users/v2/email/reset')
        .then(() => {
          mixpanel.track_auth('change email')

          return true
        })
        .catch(error => {
          console.error('Failure auth/change_email()', axios.extractErrorMessage(error), error)
          commit('ERROR', axios.extractErrorMessage(error))
          Sentry.captureException(error)
          return false
        })
    },

    reset_email({ commit }, payload) {
      commit('ERROR', null)

      return axios
        .put('/users/v2/email/reset', payload)
        .then(() => {
          mixpanel.track_auth('reset email')

          return true
        })
        .catch(error => {
          console.error('Failure auth/reset_email()', axios.extractErrorMessage(error), error)
          commit('ERROR', axios.extractErrorMessage(error))
          Sentry.captureException(error)
          return false
        })
    },

    clear_server_error({ commit }) {
      commit('ERROR', null)
    },
  },

  getters: {
    server_error: state => state.server_error,
    username: state => state.username,
    user_id: state => state.user_id,
    email: state => state.email,
    email_verified: state => state.email_verified,
    is_authenticated: state => state.is_authenticated,
    // can_access_ml_data: state => state.access_tier === 'premium' || state.access_tier === 'standard',
    // can_access_orbs_data: state => state.access_tier === 'premium' || state.access_tier === 'standard',
    // can_access_playback: state => state.access_tier === 'premium',
    // can_access_scrims: state => state.access_tier === 'premium' || SCRIM_BETA_TESTERS.includes(state.username),
    // can_access_utilities: state => state.access_tier === 'premium',
    // max_ml_round_time_millis: state => (state.access_tier === 'premium' ? Number.MAX_SAFE_INTEGER : 15000),
    can_access_ml_data: () => true,
    can_access_orbs_data: () => true,
    can_access_playback: () => true,
    can_access_utilities: () => true,
    max_ml_round_time_millis: () => Number.MAX_SAFE_INTEGER,
    can_access_grid_emea_matches: state => state?.quotas?.['xriotemea.val.esport.permission_level'] > 0,
    can_access_public_matches: state => state?.quotas?.['val.global.subscription_tier.temp'] > 0,
    can_access_collegiate_matches: state => state?.quotas?.['val.global.subscription_tier.temp'] > 0,
    can_access_scrims_v1: state => state?.quotas?.['val.global.subscription_tier.temp'] > 0,
    can_access_grid_scrims: state => state?.quotas?.['val.global.subscription_tier.temp'] >= 0,
    can_access_scrims_v2: state => state?.quotas?.['val.global.subscription_tier.temp'] >= 400,
    can_access_grid: state => state?.quotas?.['xriotemea.val.esport.permission_level'] > 0,
    persistent_data: state => state.persistent_data,
    grid_key: state => state.persistent_data?.grid_key,
    grid_positions: state => state.persistent_data?.grid_positions,
  },
}
