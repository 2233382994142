import * as Sentry from '@sentry/vue'

import { getMatchInfo, getMatchRiotData, getMatchReplayInfo } from '@/api/matches/index.js'
import { convertMinimapData } from '@/utils/convertMinimapData.js'
import { prepareMatchData } from '@/utils/prepareMatchData.js'
import prepareMinimapDetectedData from '@/utils/prepareMinimapDetectedData'

import { getMatchRoundAdvancedData } from '../../api/matches/analytics.js'
import genRoundId from '../../components/Map/utils/genRoundId.js'
import calcUtilitiesUsage from '../../utils/calcUtilitiesUsage.js'
import processMapToolData from '../../utils/processMapToolData.js'

export default {
  namespaced: true,

  state: {
    selected_map_name: null,
    selected_team_id: null,

    loading: false,
    loaded: false,
    server_error: null,
  },

  mutations: {
    SET_SELECTED_MAP_NAME: (state, map_name) => (state.selected_map_name = map_name),
    SET_SELECTED_TEAM_ID: (state, team_id) => (state.selected_team_id = team_id),

    RESET: state => {
      state.loaded = false
      state.loading = false
      state.server_error = null
    },

    ERROR: (state, error) => (state.server_error = error),
    LOADED: (state, loaded) => (state.loaded = loaded),
    LOADING: (state, loading) => (state.loading = loading),
    LOADING_START: (state, key) => state.loading[key].total++,
    LOADING_DONE: (state, key) => state.loading[key].loaded++,
  },

  getters: {
    selected_map_name: state => state.selected_map_name,
    selected_map: (state, { selected_map_name }, rootState, { 'static/getMapByName': getMapByName }) =>
      getMapByName(selected_map_name),
    selected_map_id: (state, { selected_map }) => selected_map?.id,
    selected_team_id: state => state.selected_team_id,

    loaded: state => state.loaded,
    loading: state => state.loading,
    server_error: state => state.server_error,
    loading_progress: ({ loading }) => {
      if (!loading) return ''
      const { matches, rounds, killfeeds, positions } = loading
      const p = ({ loaded, total }) => `${Math.floor((loaded / total) * 100)}%`
      return `
        matches ${p(matches)}
        rounds ${p(rounds)}
        killfeeds ${p(killfeeds)}
        positions ${p(positions)}
      `
    },
  },

  actions: {
    async fetchMapAdvancedData(
      {
        rootGetters: {
          'auth/can_access_ml_data': userCanAccessMlData,
          'auth/can_access_orbs_data': userCanAccessOrbsData,
          'auth/can_access_utilities': userCanAccessUtilities,
          // 'auth/max_ml_round_time_millis': maxMlRoundTimeMillis,
          'static/getMapById': getMapById,
          'static/agentsList': agentsList,
          'static/agentsById': agentsById,
          'static/weaponsList': weaponsList,
          'static/gearsList': gearsList,
        },
      },
      {
        mapId,
        matches: matchIds,
        config,
        isCollegiate = false,
        exclude: {
          ml: excludeMlData = false,
          orbs: excludeOrbsData = false,
          utilities: excludeUtilitiesData = false,
        } = {},
      }
    ) {
      const matchesData = []
      for (const matchId of matchIds) {
        const [info, riotData, replayInfo] = await Promise.all([
          getMatchInfo({ matchId, isCollegiate }),
          getMatchRiotData({ matchId, isCollegiate }),
          getMatchReplayInfo({ matchId, isCollegiate }),
        ])
        matchesData.push(prepareMatchData({ matchInfo: info, riotData, agents: agentsById, replayInfo }))
      }

      for (const match of matchesData) {
        const roundsData = []
        if (match.replay) {
          for (let index = 0; index < Math.min(100, match.rounds_played); index++) {
            roundsData.push(getMatchRoundAdvancedData({ matchId: match.id, roundNum: index, isCollegiate }, config))
          }
          match.advancedData = (await Promise.allSettled(roundsData)).map(outcome =>
            outcome.status === 'fulfilled' ? outcome.value : null
          )
        }
      }

      /**
       * @type {boolean}
       */
      let incompleteMlData = false
      const vodProcessedData = {}
      const orbsData = {}
      const utilitiesUsage = {}

      if (userCanAccessMlData && !excludeMlData) {
        matchIds
          .filter(match_id => {
            const match = matchesData.find(match => match.id === match_id)
            return ['succeeded', 'partial'].includes(match?.vod?.state || match?.vod_status)
          })
          .map(async match_id => {
            const match = matchesData.find(match => match.id === match_id)
            if (Array.isArray(match.advancedData)) {
              match.advancedData.forEach(roundData => {
                if (roundData) {
                  const roundId = genRoundId(match_id, roundData.round_num)
                  const minimapData = convertMinimapData(
                    roundData.minimap_detections_expand,
                    match.participants_through,
                    match.rounds.find(round => round.round_num === roundData.round_num).teams,
                    agentsList
                  )

                  vodProcessedData[roundId] = [
                    ...(minimapData?.player_locations?.map(location => {
                      return { ...location, type: 'agent' }
                    }) || []),
                    ...(minimapData?.ability_locations?.map(location => {
                      return { ...location, type: 'ability' }
                    }) || []),
                    ...(minimapData?.smoke_locations?.map(location => {
                      return { ...location, type: 'smoke' }
                    }) || []),
                    ...(minimapData?.spike_locations?.map(location => {
                      return { ...location, type: 'spike' }
                    }) || []),
                    ...(minimapData?.wall_vectors?.map(location => {
                      return { ...location, type: 'wall' }
                    }) || []),
                  ]
                  utilitiesUsage[roundId] = calcUtilitiesUsage(roundData)
                } else {
                  incompleteMlData = true
                }
              })
            }
          })
      }

      if (userCanAccessOrbsData && !excludeOrbsData) {
        matchIds
          .filter(match_id => {
            const match = matchesData.find(match => match.id === match_id)
            return (match?.vod?.state || match?.vod_status) === 'succeeded'
          })
          .map(async match_id => {
            const match = matchesData.find(match => match.id === match_id)
            if (Array.isArray(match.advancedData)) {
              orbsData[match_id] = match.advancedData
                .filter(roundData => roundData)
                .map(roundData => {
                  return {
                    round_num: roundData.round_num,
                    ultimates: roundData.sidebar_detections_expand?.ultimate_counts
                      ?.filter(ultimate_count => ultimate_count.round_time_millis === 3000)
                      .map(ultimate_count => {
                        return {
                          ...ultimate_count,
                          max: ultimate_count.max_count,
                        }
                      }),
                  }
                })
            }
          })
      }

      const mapInfo = mapId ? getMapById(mapId) : { rotate_deg: 0 }

      /**
       * @type {Record<String, API_PLAYER_POSITION[]>}
       */
      const roundAdvancedPositionsData = {}
      /**
       * @type {Record<String, API_SMOKE_EVENT[]>}
       */
      const roundSmokesData = {}
      /**
       * @type {Record<String, API_UTILITY_EVENT[]>}
       */
      const roundUtilitiesData = {}
      /**
       * @type {Record<String, API_WALL_EVENT[]>}
       */
      const roundWallsData = {}

      try {
        for (const match of matchesData) {
          for (const round of match.riotData.rounds) {
            // const maxTime = Math.min(
            //   maxMlRoundTimeMillis,
            //   round.round_length_millis - (process.env.VUE_APP_TEST_PROCESSED_DATA_SKIP_LAST || 0)
            // )
            const roundId = genRoundId(match.id, round.round_num)

            const filter = () => true // raw => raw.round_time_millis <= maxTime
            const processedData = prepareMinimapDetectedData(vodProcessedData[roundId], {
              filter,
              includeSmokes: userCanAccessUtilities && !excludeUtilitiesData,
              includeUtilities: userCanAccessUtilities && !excludeUtilitiesData,
              includeWalls: userCanAccessUtilities && !excludeUtilitiesData,
              mapRotatedDeg: mapInfo.rotate_deg,
            })
            if (!processedData) continue

            roundAdvancedPositionsData[roundId] = processedData.positions
            roundSmokesData[roundId] = processedData.smokes
            roundUtilitiesData[roundId] = processedData.utilities
            roundWallsData[roundId] = processedData.walls
          }
        }
      } catch (e) {
        console.warn('Error while preparing processed data', e)
        Sentry.captureException(e)
        // noinspection ExceptionCaughtLocallyJS
        throw e
      }

      return {
        ...(await processMapToolData({
          staticData: { agentsData: agentsList, weaponsData: weaponsList, gearsData: gearsList },
          matchesData: matchesData,
          orbsData,
          roundAdvancedPositionsData,
          roundSmokesData,
          roundUtilitiesData,
          roundWallsData,
          roundUtilitiesUsageData: utilitiesUsage,
          mapId,
        })),
        incompleteMlData,
      }
    },
    async fetch_data(
      { commit, dispatch, rootGetters: { 'static/getMapByName': getMapByName } },
      { map_name, mapId = getMapByName(map_name).id, team_id, matches, config, isCollegiate = false }
    ) {
      console.info(`%c map/fetch_data(${map_name}, ${team_id}, ${matches.join(',')})`, 'color:#38bfb2')

      // store parameters
      commit('SET_SELECTED_MAP_NAME', map_name)
      commit('SET_SELECTED_TEAM_ID', team_id)
      // reset
      commit('RESET')
      // and switch to loading
      commit('LOADING', true)

      try {
        const data = await dispatch('fetchMapAdvancedData', { mapId, matches, config, isCollegiate })
        commit('LOADED', true)
        return data
      } catch (error) {
        console.warn(error, { ...error })
        commit('ERROR', error.response?.data.detail || error.message)
        Sentry.captureException(error)
        throw error
      } finally {
        commit('LOADING', false)
      }
    },

    update_selected_team_id({ commit, state }, team_id) {
      console.info(`%c map/update_selected_team_id(${team_id})`, 'color:#38bfb2')

      // no need to clear data if same team
      if (state.selected_team_id === team_id) return

      commit('SET_SELECTED_TEAM_ID', team_id)
      commit('RESET')
    },

    update_selected_map_name({ commit, state }, map_name) {
      console.info(`%c map/update_selected_map_name(${map_name})`, 'color:#38bfb2')

      // no need to clear data if same team
      if (state.selected_map_name === map_name) return

      commit('SET_SELECTED_MAP_NAME', map_name)
      commit('RESET')
    },

    clear_state({ commit }) {
      console.info('%c map/clear_state()', 'color:#38bfb2')

      commit('RESET')
    },
  },
}
