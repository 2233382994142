// TODO Testing coverage for Match APIs

import * as Sentry from '@sentry/vue'
import Vue from 'vue'

import { getMatchDetectedOrbs } from '../../api/matches/analytics.js'
import { getMatchInfo, getMatchSummary, updateMatchVod } from '../../api/matches/index.js'
import { getMatchRoundInfo } from '../../api/matches/rounds.js'
import { getXurl } from '../../api/xurl.js'
import axios from '../../axios.js'
import { deepFreeze, sort_by_score } from '../../helper.js'

export default {
  namespaced: true,

  state: {
    all_rounds: {},
    duration: null,
    economy_first_half: null,
    economy_preview: null,
    economy_second_half: null,
    loading_match: false,
    loading_round: false,
    map_id: null,
    match_date: 1,
    match_id: null,
    round_active: null,
    round_replay_url: null,
    round_stats: [], // merges into response state.teams ???
    selected_round_numbers: {},
    server_error: null,
    start_time: {},
    stats: {},
    summary_id: null,
    summary: null,
    teams: [],
    vod_status: 'init',
  },

  mutations: {
    DATE: (state, match_date) => (state.match_date = match_date),
    ERROR: (state, error) => (state.server_error = error),
    ID: (state, match_id) => (state.match_id = match_id),
    SET_MAP_ID: (state, mapId) => (state.map_id = mapId),
    SET_ECONOMY_FIRST_HALF: (state, data) => (state.economy_first_half = data),
    SET_ECONOMY_PREVIEW: (state, data) => (state.economy_preview = data),
    SET_ECONOMY_SECOND_HALF: (state, data) => (state.economy_second_half = data),
    SET_ROUND_ACTIVE: (state, data) => (state.round_active = data),
    SET_ROUND_REPLAY_URL: (state, data) => (state.round_replay_url = data),
    SET_ROUND_STATS: (state, data) => (state.round_stats = data),
    SET_START_TIME: (state, { match_id, start_time }) => Vue.set(state.start_time, match_id, start_time),
    SET_STATS: (state, { match_id, stats }) => Vue.set(state.stats, match_id, deepFreeze(stats)),
    SET_TEAMS: (state, teams) => (state.teams = teams),
    SET_VOD_STATUS: (state, data) => (state.vod_status = data),
    SUMMARY_ID: (state, data) => (state.summary_id = data),
    SUMMARY: (state, data) => (state.summary = data),
    UPDATE_ALL_ROUNDS: (state, rounds) => (state.all_rounds = rounds),
    UPDATE_DURATION: (state, duration) => (state.duration = duration),
    UPDATE_LOADING_MATCH: (state, data) => (state.loading_match = data),
    UPDATE_LOADING_ROUND: (state, data) => (state.loading_round = data),
    UPDATE_SELECTED_ROUND_NUMBERS: (state, id) => (state.selected_round_numbers = id),
  },

  getters: {
    all_rounds: state => state.all_rounds,
    duration: state => state.duration,
    economy_first_half: state => state.economy_first_half,
    economy_preview: state => state.economy_preview,
    economy_second_half: state => state.economy_second_half,
    loading_match: state => state.loading_match,
    loading_round: state => state.loading_round,
    map: (state, getters, rootState, { 'static/getMapById': getMapById }) => getMapById(state.map_id)?.name,
    map_id: state => state.map_id,
    match_date: state => state.match_date,
    match_id: state => state.match_id,
    match_stats: state => state.stats[state.match_id],
    round_active: state => state.round_active,
    round_replay_url: state => state.round_replay_url,
    round_stats: state => state.round_stats,
    selected_round_numbers: state => state.selected_round_numbers,
    server_error: state => state.server_error,
    start_time: state => state.start_time[state.match_id],
    summary_id: state => state.summary_id,
    summary: state => state.summary,
    teams: state => state.teams,
    vod_status: state => state.vod_status,
  },

  actions: {
    async get_round_stats({ commit, state, rootGetters }, params) {
      const { match_id, round: roundStr, config } = params
      const round = parseInt(roundStr, 10)

      console.info(`%c match/get_round_stats({ ${match_id}, ${roundStr} })`, 'color:#38bfb2')

      commit('UPDATE_LOADING_ROUND', true)
      commit('SET_ROUND_ACTIVE', null)
      commit('SET_ROUND_STATS', [])
      commit('SET_ROUND_REPLAY_URL', null)

      try {
        const [roundsData, orbsData] = await Promise.all([
          getMatchRoundInfo(match_id, round, config),
          rootGetters['auth/can_access_orbs_data']
            ? getMatchDetectedOrbs(match_id)
                .then(xurl => getXurl(xurl))
                .then(data => (Array.isArray(data) ? data : []))
            : [],
        ])

        const round_stats = []

        roundsData.teams.forEach(res_team => {
          let round_stats_players = []
          const [team] = state.teams.filter(team => team.id === res_team.id)

          res_team.players.forEach(player => {
            const [team_player] = team.players.filter(p => player.puuid === p.puuid)

            round_stats_players.push({
              ...player,
              name: team_player.name,
              agent_name: team_player.agent_name,
              agent_thumbnail: team_player.agent_name
                .replace('/', '') // "KAY/O" case
                .toLowerCase(),
              avr_round_score: team_player.avr_round_score,
              ultimates: orbsData
                .find(roundOrbs => roundOrbs.round_num === round)
                ?.ultimates?.find(orbData => orbData.puuid === player.puuid),
              ...(player.armor
                ? { armor_url: rootGetters['static/getGearById'](player.armor.toLowerCase())?.display_icon_url }
                : {}),
            })
          })

          round_stats.push({
            id: team.id,
            name: team.name,
            icon_url: team.icon_url,
            score: team.score,
            players: round_stats_players,
          })
        })

        round_stats.sort(sort_by_score)

        commit('SET_ROUND_ACTIVE', roundsData.round_num)
        commit('SET_ROUND_STATS', round_stats)
        commit('SET_ROUND_REPLAY_URL', roundsData.replay_url)
        return round_stats
      } catch (error) {
        console.error(
          `Failure match/get_round_stats({ ${match_id}, ${roundStr} }`,
          axios.extractErrorMessage(error),
          error
        )
        commit('ERROR', axios.extractErrorMessage(error))
        Sentry.captureException(error)
        throw error
      } finally {
        commit('UPDATE_LOADING_ROUND', false)
      }
    },

    get_match({ commit, state }, match_id) {
      console.info(`%c match/get_match(${match_id})`, 'color:#38bfb2')

      // prevent refetching the same match
      if (match_id === state.match_id) {
        return
      }

      commit('ID', null)
      commit('SET_MAP_ID', null)
      commit('SET_TEAMS', null)
      commit('SET_ECONOMY_PREVIEW', null)
      commit('SET_ECONOMY_FIRST_HALF', null)
      commit('SET_ECONOMY_SECOND_HALF', null)
      commit('UPDATE_LOADING_MATCH', true)
      commit('ERROR', null)
      commit('SET_VOD_STATUS', 'init')

      return getMatchInfo({ matchId: match_id })
        .then(data => {
          const mapId = data.map_id
          const teams = []
          const economy_preview = []
          let economy_first_half = []
          let economy_second_half = []

          const stats = {}

          for (const res_team_obj in data.teams) {
            const res_team = data.teams[res_team_obj]
            let players = []
            let pistol_won = 0
            let eco_played = 0
            let eco_won = 0
            let semi_eco_played = 0
            let semi_eco_won = 0
            let semi_buy_played = 0
            let semi_buy_won = 0
            let full_buy_played = 0
            let full_buy_won = 0

            const addStat = (name, key, value) => {
              if (!(res_team.id in stats)) {
                stats[res_team.id] = {}
              }
              if (!(name in stats[res_team.id])) {
                stats[res_team.id][name] = {}
              }
              if (!(key in stats[res_team.id][name])) {
                stats[res_team.id][name][key] = 0
              }
              stats[res_team.id][name][key] += value
            }

            players = res_team.players.map(player => {
              const [id] = data.players.filter(p => p.puuid === player.puuid).map(p => p.player)

              return {
                ...player,
                id,
                agent_thumbnail: player.agent_name
                  .replace('/', '') // "KAY/O" case
                  .toLowerCase(),
              }
            })

            const teamRoles = {}

            // Economy
            res_team.halves.forEach((half, i) => {
              const role = half.role.toLowerCase()

              // First half
              if (i === 0) {
                teamRoles.first = role
                economy_first_half.push({
                  id: res_team.id,
                  name: res_team.name,
                  icon_url: res_team.icon_url,
                  role: half.role.toLowerCase(),
                  rounds: half.rounds,
                })

                // Second halh
              } else {
                teamRoles.second = role
                economy_second_half.push({
                  id: res_team.id,
                  name: res_team.name,
                  icon_url: res_team.icon_url,
                  role: half.role.toLowerCase(),
                  rounds: half.rounds,
                })
              }

              half.rounds.forEach((round, roundIndex) => {
                switch (round.eco) {
                  case 'P':
                    if (round.win) {
                      pistol_won += 1
                    }
                    break
                  case '$':
                    eco_played += 1
                    if (round.win) {
                      eco_won += 1
                    }
                    break

                  case '$$':
                    semi_eco_played += 1
                    if (round.win) {
                      semi_eco_won += 1
                    }
                    break

                  case '$$$':
                    semi_buy_played += 1
                    if (round.win) {
                      semi_buy_won += 1
                    }
                    break

                  case '$$$$':
                    full_buy_played += 1
                    if (round.win) {
                      full_buy_won += 1
                    }
                    break

                  default:
                    console.error(`Unhandled round eco ${round.eco}`)
                    Sentry.captureException(new Error(`Unhandled round eco ${round.eco}`))
                    break
                }

                addStat('byRole', role, round.win ? 1 : 0)
                if (i === 0) {
                  addStat('byHalf', 'first', round.win ? 1 : 0)
                } else {
                  if (roundIndex < 12) {
                    addStat('byHalf', 'second', round.win ? 1 : 0)
                  } else {
                    addStat('byHalf', 'overtime', round.win ? 1 : 0)
                  }
                }
              })
            })

            // `teams` is used for both Overview and Performance
            teams.push({
              id: res_team.id,
              name: res_team.name,
              is_winner: res_team.is_winner,
              score: res_team.score,
              icon_url: res_team.icon_url,
              roles: teamRoles,
              players,
            })

            economy_preview.push({
              id: res_team.id,
              team_name: res_team.name,
              icon_url: res_team.icon_url,
              pistol_won,
              eco: {
                played: eco_played,
                won: eco_won,
              },
              semi_eco: {
                played: semi_eco_played,
                won: semi_eco_won,
              },
              semi_buy: {
                played: semi_buy_played,
                won: semi_buy_won,
              },
              full_buy: {
                played: full_buy_played,
                won: full_buy_won,
              },
            })
          }

          // Sort teams by winner
          teams.sort(sort_by_score)

          commit('ID', match_id)
          commit('SET_MAP_ID', mapId)
          commit('SET_TEAMS', teams)
          commit('SET_ECONOMY_PREVIEW', economy_preview)
          commit('SET_ECONOMY_FIRST_HALF', economy_first_half)
          commit('SET_ECONOMY_SECOND_HALF', economy_second_half)
          commit('UPDATE_LOADING_MATCH', false)
          commit('SET_VOD_STATUS', data.vod?.state)
          commit('SET_START_TIME', { match_id, start_time: data.start_time })
          commit('SET_STATS', { match_id, stats })
          // commit('DATE', date)
        })
        .catch(error => {
          console.error(`Failure match/get_match(${match_id})`, error)
          commit('ERROR', error.response?.data.detail)
          Sentry.captureException(error)
        })
    },

    get_summary({ commit, state }, match_id) {
      console.info(`%c match/get_summary(${match_id})`, 'color:#38bfb2')

      // prevent fetching the same match summary
      if (match_id === state.summary_id) {
        return
      }

      // reset stuff
      commit('SUMMARY', null)
      commit('SUMMARY_ID', null)

      return getMatchSummary(match_id)
        .then(summary => {
          // TODO make model
          const updated_summary = []
          let wins_blue = 0
          let wins_red = 0
          let first_half = true
          let order = null

          summary.rounds.forEach((round, i, all_rounds) => {
            if (summary.rounds[i - 1] && summary.rounds[i - 1].teams[0].role !== round.teams[0].role) {
              first_half = false
            }
            order = first_half ? -(all_rounds.length - i) : i

            if (round.winning_team === 'Blue') {
              wins_blue++
            } else {
              wins_red++
            }

            updated_summary.push({
              first_half,
              round_number: round.round_num,
              order,
              teams: summary.teams
                .sort(team => (team.won ? -1 : 1))
                .map(team => {
                  const name = team.name

                  const grid = team.grid

                  const role = round.teams.filter(round_team => round_team.id === team.id)[0].role.toLowerCase()

                  const kills = round.teams.filter(round_team => round_team.id === team.id)[0].kills

                  const won = round.teams.filter(round_team => round_team.id === team.id)[0].grid === round.winning_team

                  const won_by = won ? round.outcome || 'Time' : null

                  const score =
                    round.teams.filter(round_team => round_team.id === team.id)[0].grid === 'Blue'
                      ? wins_blue
                      : wins_red

                  const loadout = round.teams.filter(round_team => round_team.id === team.id)[0].loadout_value

                  const eco = round.teams.filter(round_team => round_team.id === team.id)[0].eco

                  const plant = role === 'atk' ? round.plant_site || null : null

                  return { id: team.id, name, grid, role, kills, won, won_by, score, loadout, eco, plant }
                }),
            })
          })

          commit('SUMMARY', updated_summary)
          commit('SUMMARY_ID', match_id)
          return summary
        })
        .catch(error => {
          console.error(`Failure match/get_summary(${match_id})`, error)
          Sentry.captureException(error)
          return Promise.reject(error)
        })
    },

    update_vod({ commit, state }, params) {
      const match_id = params.match_id || state.match_id

      commit('ERROR', null)

      return updateMatchVod(match_id, params)
        .then(() => commit('SET_VOD_STATUS', 'queued'))
        .catch(error => {
          console.error(`Failure update_vod(${match_id})`, error)
          commit('ERROR', error.response.data.detail)
          commit('SET_VOD_STATUS', 'failed')
          Sentry.captureException(error)
        })
    },

    clear_state({ commit }) {
      console.info('%c match/clear_state()', 'color:#38bfb2')

      commit('ID', null)
      commit('MAP', null)
      commit('SET_TEAMS', null)
      commit('SET_ECONOMY_PREVIEW', null)
      commit('SET_ECONOMY_FIRST_HALF', null)
      commit('SET_ECONOMY_SECOND_HALF', null)
      commit('UPDATE_LOADING_MATCH', true)
      commit('ERROR', null)
      commit('SET_VOD_STATUS', 'init')
      commit('SET_ROUND_ACTIVE', null)
      commit('SET_ROUND_STATS', [])
      commit('SET_ROUND_REPLAY_URL', null)
      commit('SUMMARY', null)
      commit('SUMMARY_ID', null)
    },
  },
}
