function binarySearch(array, pred) {
  let lo = -1
  let hi = array.length
  while (1 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    if (pred(array[mi])) {
      hi = mi
    } else {
      lo = mi
    }
  }
  return hi
}

export default binarySearch
