import * as Sentry from '@sentry/vue'

import { createBookmark, deleteBookmark, listBookmarks } from '../../api/bookmarks.js'
import axios from '../../axios.js'

export default {
  namespaced: true,

  state: {
    totals: {
      match: null,
      team: null,
    },
  },

  getters: {
    countMatches: state => state.totals.match,
    countTeams: state => state.totals.team,
  },

  mutations: {
    CHANGE_TOTAL(state, { type, value }) {
      if (!(type in state.totals)) throw new Error(`Invalid bookmark type ${type}`)
      if (state.totals[type] != null) {
        state.totals[type] = state.totals[type] + (value ? 1 : -1)
      }
    },
    SET_TOTAL(state, { type, value }) {
      if (!(type in state.totals)) throw new Error(`Invalid bookmark type ${type}`)
      state.totals[type] = value
    },
  },

  actions: {
    async create({ dispatch }, { type, id }) {
      return dispatch('set', { type, id, value: true })
    },
    async delete({ dispatch }, { type, id }) {
      return dispatch('set', { type, id, value: false })
    },
    async list({ commit }, { type, count, index, cancelToken }) {
      const result = await listBookmarks({ type, count, index }, { cancelToken })
      commit('SET_TOTAL', { type, value: result.total })
      return result
    },
    async set({ commit }, { type, id, value = true }) {
      try {
        if (value) {
          await createBookmark({ type, id })
        } else {
          await deleteBookmark({ type, id })
        }
        commit('CHANGE_TOTAL', { type, value })
      } catch (e) {
        console.error(axios.extractErrorMessage(e), e)
        Sentry.captureException(e)
      }
    },
  },
}
