import axios from '../../axios.js'

/**
 *
 * @param {string} matchId
 * @param {number} roundNum
 * @param cancelToken
 * @return {Promise<API_MATCH_ROUND_INFO>}
 */
export const getMatchRoundInfo = async (matchId, roundNum, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/matches/${matchId}/rounds/${roundNum}`, { cancelToken })
  return response.data
}
