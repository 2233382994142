import axios from '../axios.js'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../store/modules/search.js'
import agentCompositionToApi from '../utils/agentCompositionToApi.js'
import { periodToApi } from '../utils/period.js'

/**
 *
 * @param {string} teamId
 * @param cancelToken
 * @return {Promise<API_TEAM_INFO>}
 */
export const getTeamInfo = async (teamId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v3/esport/teams/${teamId}`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} teamId
 * @param cancelToken
 * @return {Promise<API_TEAM_OVERVIEW>}
 */
export const getTeamOverview = async (teamId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/teams/${teamId}/overview`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} teamId
 * @param cancelToken
 */
export const getTeamGeneralStats = async (teamId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v3/esport/teams/${teamId}/stats/matches/general`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} teamId
 * @param {string[]} map_ids
 * @param {string[]} platforms
 * @param {Array<Array<string>>} agent_comps
 * @param {string[]} team_vs_ids
 * @param {string|null} begin_time
 * @param {string|null} end_time
 * @param {PACKED_PERIOD|UNPACKED_PERIOD} period
 * @param {boolean} [has_vod]
 * @param {number} count
 * @param {number} index
 * @param cancelToken
 * @return {Promise<API_TEAM_STATS>}
 */
export const getTeamStats = async (
  teamId,
  {
    map_ids = [],
    platforms = [],
    agent_comps = [],
    team_vs_ids = [],
    begin_time = null,
    end_time = null,
    period = { begin_time, end_time },
    has_vod,
    count = DEFAULT_ITEMS_PER_PAGE,
    index = DEFAULT_PAGE_INDEX,
  } = {},
  { cancelToken } = {}
) => {
  const response = await axios.get(`/val/v3/esport/teams/${teamId}/stats/matches/riot`, {
    cancelToken,
    params: {
      maps: map_ids,
      platforms,
      agent_comps: (agent_comps || []).map(agentCompositionToApi),
      vs_teams: team_vs_ids,
      ...periodToApi(period),
      has_vod: has_vod ? true : null,
      limit: count,
      offset: (index - 1) * count,
    },
  })
  return response.data
}
