import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import agents from './modules/agents.js'
import auth from './modules/auth.js'
import bookmarks from './modules/bookmarks.js'
import collegiateSearch from './modules/collegiateSearch.js'
import map from './modules/map.js'
import match from './modules/match.js'
import player from './modules/player.js'
import rounds from './modules/rounds.js'
import scrims from './modules/scrims.js'
import search from './modules/search.js'
import serverNotices from './modules/serverNotices.js'
import _static from './modules/static.js'
import team from './modules/team.js'
import storage from './storage.js'

const vuexLocal = new VuexPersistence({
  storage,
  reducer: state => ({
    collegiateSearch: state.collegiateSearch,
    scrims: {
      lastCompositionsPerMap: state.scrims.lastCompositionsPerMap,
      lastTeam1Name: state.scrims.lastTeam1Name,
      lastTeam2Name: state.scrims.lastTeam2Name,
    },
    search: state.search,
    searchGrid: state.searchGrid,
    serverNotices: {
      hidden: state.serverNotices.hidden,
    },
  }),
})

Vue.use(Vuex)

export const store = {
  strict: true,
  modules: {
    agents,
    auth,
    bookmarks,
    collegiateSearch,
    map,
    match,
    player,
    rounds,
    scrims,
    search,
    searchGrid: search,
    serverNotices,
    static: _static,
    team,
  },
  plugins: [vuexLocal.plugin],
}

export default new Vuex.Store(store)
