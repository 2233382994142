import axios from '../axios.js'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../store/modules/search.js'
import { periodToApi } from '../utils/period.js'
import stringCompare from '../utils/stringCompare.js'

/**
 *
 * @param {string} playerId
 * @param cancelToken
 * @return {Promise<API_PLAYER_INFO>}
 */
export const getPlayerInfo = async (playerId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/players/${playerId}`, { cancelToken })
  return response.data
}

/**
 *
 * @param {string} playerId
 * @param {string[]} map_ids
 * @param {string[]} platforms
 * @param {Array<Array<string>>} agent_comps
 * @param {string[]} team_vs_ids
 * @param {string|null} begin_time
 * @param {string|null} end_time
 * @param {PACKED_PERIOD|UNPACKED_PERIOD} period
 * @param {boolean} [has_vod]
 * @param {number} count
 * @param {number} index
 * @param cancelToken
 * @return {Promise<API_TEAM_STATS>}
 */
export const getPlayerStats = async (
  playerId,
  {
    map_ids = [],
    platforms = [],
    agent_comps = [],
    team_vs_ids = [],
    begin_time = null,
    end_time = null,
    period = { begin_time, end_time },
    has_vod,
    count = DEFAULT_ITEMS_PER_PAGE,
    index = DEFAULT_PAGE_INDEX,
  } = {},
  { cancelToken } = {}
) => {
  const response = await axios.get(`/val/v2/players/${playerId}/stats`, {
    cancelToken,
    params: {
      map_ids,
      platforms,
      agent_comps: (agent_comps || []).map(list => [...list].sort(stringCompare).join(',')),
      team_vs_ids,
      ...periodToApi(period),
      has_vod: has_vod ? true : null,
      count,
      index,
    },
  })
  return response.data
}
