import * as Sentry from '@sentry/vue'

export const prepareMatchData = ({ matchInfo, riotData, agents, replayInfo }) => {
  const matchData = {
    ...matchInfo,
    riotData,
    replay: replayInfo,
  }

  matchData.teams.sort(team => (team.win ? -1 : 1))

  matchData.vod_status = matchInfo.replay_cropper_status

  matchData.teams.forEach(team => {
    team.players = []
    matchData.participants_through.forEach(participant => {
      if (participant.team === team.id) {
        team.players.push({
          ...participant,
          ...riotData?.players.find(player => player.puuid === participant.puuid),
          id: participant.player,
          agent_name: agents[participant.agent].name,
          agent_icon_url: agents[participant.agent].display_icon_url,
        })
      }
    })
  })
  matchData.roundStats = calcRoundStats(matchData)
  matchData.economy = calcEconomy(matchData)
  matchData.rounds = riotData?.rounds?.map(roundData => {
    return { ...roundData, teams: matchData.roundStats.find(round => round.round_number === roundData.round_num).teams }
  })

  return matchData
}

const calcRoundStats = matchData => {
  const roundsSummary = []
  let wins_blue = 0
  let wins_red = 0
  let order = null

  matchData.riotData?.rounds.forEach((roundInfo, i, allRounds) => {
    const firstHalf = roundInfo.round_num < 12
    order = firstHalf ? -(allRounds.length - i) : i

    if (roundInfo.winning_team_side === 'Blue') {
      wins_blue++
    } else {
      wins_red++
    }

    roundsSummary.push({
      first_half: firstHalf,
      round_number: roundInfo.round_num,
      order,
      teams: matchData.teams.map(team => {
        const name = team.name
        const grid = team.team_side
        const roundTeam = roundInfo.team_stats.find(roundTeam => roundTeam.side === team.team_side)
        const role = roundTeam.role
        const kills = roundTeam.kills
        const won = roundTeam.side === roundInfo.winning_team_side
        const won_by = won ? roundInfo.result_code || 'Time' : null
        const score = roundTeam.side === 'Blue' ? wins_blue : wins_red
        const loadout = roundTeam.loadout_value
        const eco = roundTeam.eco_type
        const plant = role === 'atk' ? roundInfo.plant_side || null : null
        const remaining = roundTeam.remaining
        const pistol_won = won && roundInfo.is_pistol
        return {
          id: team.id,
          name,
          grid,
          role,
          kills,
          won,
          won_by,
          score,
          loadout,
          eco,
          plant,
          remaining,
          pistol_won,
        }
      }),
    })
  })
  return roundsSummary
}

const calcEconomy = matchData => {
  const economy = {
    economyPreview: [],
    economyFirstHalf: [],
    economySecondHalf: [],
  }
  matchData.teams.forEach(team => {
    let firstHalfRole = null
    let seconHalfRole = null
    let firstHalfRounds = []
    let secondHalfRounds = []

    const ecoTeam = {
      id: team.team,
      team_name: team.name,
      icon_url: team.logo_url,
      pistol_won: 0,
      eco: {
        played: 0,
        won: 0,
      },
      semi_eco: {
        played: 0,
        won: 0,
      },
      semi_buy: {
        played: 0,
        won: 0,
      },
      full_buy: {
        played: 0,
        won: 0,
      },
    }

    matchData.roundStats.forEach(roundStat => {
      const teamStats = roundStat.teams.find(statsTeam => statsTeam.id === team.id)
      if (firstHalfRole === null && roundStat.first_half) {
        firstHalfRole = teamStats.role
      }
      if (seconHalfRole === null && !roundStat.first_half && roundStat.round_number < 24) {
        seconHalfRole = teamStats.role
      }

      const economyRoundStats = {
        bank: teamStats.remaining,
        eco: teamStats.eco,
        loadout: teamStats.loadout,
        outcome: teamStats.won_by,
        pistol_won: teamStats.pistol_won,
        win: teamStats.won,
      }

      if (roundStat.first_half) {
        firstHalfRounds.push(economyRoundStats)
      } else {
        secondHalfRounds.push(economyRoundStats)
      }

      switch (teamStats.eco) {
        case 'P':
          if (teamStats.won) {
            ecoTeam.pistol_won += 1
          }
          break
        case '$':
          ecoTeam.eco.played += 1
          if (teamStats.won) {
            ecoTeam.eco.won += 1
          }
          break
        case '$$':
          ecoTeam.semi_eco.played += 1
          if (teamStats.won) {
            ecoTeam.semi_eco.won += 1
          }
          break

        case '$$$':
          ecoTeam.semi_buy.played += 1
          if (teamStats.won) {
            ecoTeam.semi_buy.won += 1
          }
          break

        case '$$$$':
          ecoTeam.full_buy.played += 1
          if (teamStats.won) {
            ecoTeam.full_buy.won += 1
          }
          break
        default:
          console.error(`Unhandled round eco ${teamStats.eco}`)
          Sentry.captureException(new Error(`Unhandled round eco ${teamStats.eco}`))
          break
      }
    })
    economy.economyPreview.push(ecoTeam)
    economy.economyFirstHalf.push({
      icon_url: ecoTeam.icon_url,
      id: ecoTeam.id,
      name: ecoTeam.team_name,
      role: firstHalfRole,
      rounds: firstHalfRounds,
    })
    economy.economySecondHalf.push({
      icon_url: ecoTeam.icon_url,
      id: ecoTeam.id,
      name: ecoTeam.team_name,
      role: seconHalfRole,
      rounds: secondHalfRounds,
    })
  })
  return economy
}
