// exclude these abilities in abilities usages filter
export default [
  'Healing Orb',
  'Resurrection',
  'Spy Cam',
  'Trapwire',
  'Neural Theft',
  'Devour',
  'Dismiss',
  'Empress',
  'Alarm bot',
  'Nano Swarm',
  'Turret',
  'Regrowth',
  'Headhunter',
  'Rendezvous',
  'Tour De Force',
  'Trademark',
  'Nova Pulse',
  'Nebula  / Dissipate',
  'Gravity Well',
  'Astral Form / Cosmic Divide',
  'Astral Form',
]
