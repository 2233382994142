import * as Sentry from '@sentry/vue'
import Vue from 'vue'

import { listNotices } from '../../api/notices.js'
import axios from '../../axios.js'

const { freeze } = Object

export default {
  namespaced: true,

  state: {
    error: null,
    notices: [],
    hidden: {},
  },

  getters: {
    error: state => state.error,
    notices: state => freeze(state.notices.filter(notice => !state.hidden[notice.id])),
  },

  mutations: {
    SET_ERROR: (state, error) => {
      state.error = error
    },
    SET_NOTICES: (state, notices) => {
      state.notices = freeze(notices)
    },
    HIDE_NOTICE: (state, notice) => {
      Vue.set(state.hidden, notice.id, true)
    },
  },

  actions: {
    async fetchNotices({ commit }) {
      try {
        commit('SET_ERROR', null)
        const notices = await listNotices()
        commit('SET_NOTICES', notices)
      } catch (error) {
        const errorMsg = axios.extractErrorMessage(error)
        console.error('Failed to fetch notices', errorMsg, error)
        commit('SET_ERROR', errorMsg)
        Sentry.captureException(error)
      }
    },
  },
}
