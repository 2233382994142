export const SCRIM_MAX_ROUND_TIME_IN_SECONDS = 141

export const ECONOMY_LEVELS = {
  match: {
    $: {
      max: 5000,
      name: 'Eco',
      rangeLabel: '0-5k',
      color: '#FFA500',
    },
    $$: {
      min: 5000,
      max: 10000,
      name: 'Semi-cco',
      rangeLabel: '5-10k',
      color: '#FFFF00',
    },
    $$$: {
      min: 10000,
      max: 20000,
      name: 'Semi-buy',
      rangeLabel: '10-20k',
      color: '#31F000',
    },
    $$$$: {
      min: 20000,
      max: 50000,
      name: 'Full buy',
      rangeLabel: '20k+',
      color: '#00A000',
    },
  },
  scrim: {
    $: {
      max: 5000,
      name: 'Eco',
      rangeLabel: '0-5k',
      color: '#FFA500',
    },
    $$: {
      min: 5000,
      max: 10000,
      name: 'Semi-cco',
      rangeLabel: '5-10k',
      color: '#FFFF00',
    },
    $$$: {
      min: 10000,
      max: 16500,
      name: 'Semi-buy',
      rangeLabel: '10-16.5k',
      color: '#31F000',
    },
    $$$$: {
      min: 16500,
      max: 50000,
      name: 'Full-buy',
      rangeLabel: '16.5k+',
      color: '#00A000',
    },
  },
}

// max time in ms for two smokes to be considered as one
export const MAX_SMOKE_GAP = 5000
// brimstone has 19.25s smoke duration with some buffer for discrete detection on every 100ms, it could appear as 19.5s
export const MAX_SMOKE_DURATION = 20000
// coeficient how much smoke can shift compared to its radius
export const MAX_SMOKE_RADIUS_DISTANCE = 1 / 3
// maximal distance between two smokes to be considered as one
export const MAX_SMOKE_DISTANCE = 64 / 1024
// max time in ms for two walls to be considered as one
export const MAX_WALL_GAP = 5000
// viper's wall does not show if it's active or not, so it can be inactive for the whole round
export const MAX_WALL_DURATION = Number.MAX_SAFE_INTEGER
// maximal distance between two walls to be considered as one
export const MAX_WALL_DISTANCE = 64 / 1024
// Opacity for faded map events drawn in 2D reaplay map
export const MAP_FADED_EVENTS_OPACITY = 0.3

// version of the bookmarks format
export const BOOKMARK_VERSION = 8
// size of thumbnails in bookmarks
export const BOOKMARK_THUMBNAIL_SIZE = 128

// max time difference for interpolating event positions
export const MAX_TIME_DIFF = 5000

export const MAX_AGENT_SPEED = 0.000111

export const VIDEO_AD_ROUNDS_COUNT = 5
