import reportException from '@/utils/reportException'
import rotate from '@/utils/rotateLocation'

/**
 * @typedef {{
 *   round_time_millis: number,
 *   puuid: string,
 *   location: API_LOCATION,
 * }} PRE_DETECTED_POSITION
 */

/**
 * @typedef {{
 *   round_time_millis: number,
 *   location: API_LOCATION,
 *   radius: number,
 * }} PRE_DETECTED_SMOKE
 */

/**
 * @typedef {{
 *   round_time_millis: number,
 *   puuid: string,
 *   ability_slot: API_ABILITY_SLOT,
 *   location: API_LOCATION,
 * }} PRE_DETECTED_UTILITY
 */

/**
 * @typedef {{
 *   round_time_millis: number,
 *   role: API_TEAM_ROLE,
 *   location: API_LOCATION,
 *   vector: Array<API_LOCATION>,
 * }} PRE_DETECTED_WALL
 */

/**
 * @param {Array<API_PROCESSED_EVENT>} detectedData
 * @param {(API_PROCESSED_EVENT) => boolean} filter
 * @param {boolean} includePositions
 * @param {boolean} includeSmokes
 * @param {boolean} includeUtilities
 * @param {boolean} includeWalls
 * @param {number} mapRotatedDeg
 * @return {{
 *   positions: Array<PRE_DETECTED_POSITION>,
 *   smokes: Array<PRE_DETECTED_SMOKE>,
 *   utilities: Array<PRE_DETECTED_UTILITY>,
 *   walls: Array<PRE_DETECTED_WALL>,
 * }}
 */
export default function prepareMinimapDetectedData(
  detectedData,
  {
    filter = () => true,
    includePositions = true,
    includeSmokes = true,
    includeUtilities = true,
    includeWalls = true,
    mapRotatedDeg = 0,
  } = {}
) {
  if (!detectedData) return

  const processed = {
    positions: [],
    smokes: [],
    utilities: [],
    walls: [],
  }

  detectedData.forEach(raw => {
    if (!filter(raw)) return
    // if (raw.conf != null && raw.conf < 0.9) return
    switch (raw.type) {
      case 'ability':
        if (includeUtilities) {
          processed.utilities.push({
            round_time_millis: raw.round_time_millis,
            puuid: raw.puuid,
            ability_slot: raw.slot.toLowerCase(),
            location: rotate(raw.location, -mapRotatedDeg),
            ...(raw.track_id ? { track_id: raw.track_id } : {}),
          })
        }
        break
      case 'agent':
        if (includePositions) {
          processed.positions.push({
            round_time_millis: raw.round_time_millis,
            puuid: raw.puuid,
            location: rotate(raw.location, -mapRotatedDeg),
            ...(raw.track_id ? { track_id: raw.track_id } : {}),
          })
        }
        break
      case 'smoke':
        if (includeSmokes) {
          processed.smokes.push({
            round_time_millis: raw.round_time_millis,
            location: rotate(raw.location, -mapRotatedDeg),
            radius: raw.radius,
            ...(raw.track_id ? { track_id: raw.track_id } : {}),
          })
        }
        break
      case 'wall':
        if (includeWalls) {
          processed.walls.push({
            round_time_millis: raw.round_time_millis,
            role: raw.role,
            location: rotate(raw.location, -mapRotatedDeg),
            vector: raw.vector.map(l => rotate(l, -mapRotatedDeg)),
            ...(raw.track_id ? { track_id: raw.track_id } : {}),
          })
        }
        break
      case 'spike':
        // ignore
        break
      default:
        reportException(`Unhandled minimap detected type "${raw.type}"`)
        break
    }
  })
  return processed
}
