import * as Sentry from '@sentry/vue'

/**
 * @type {Record<string, (any) => object>}
 */
const PARAM_TO_TAG = {
  matchData: matchData => ({ matchId: matchData.id }),
  roundData: roundData => ({ roundNum: roundData.round_num }),
  killData: killData => ({
    eventType: 'kill',
    roundTimeMillis: killData.round_time_millis,
    damageItem: killData.finishing_damage.damage_item,
    damageType: killData.finishing_damage.damage_type,
  }),
  event: event => ({
    matchId: event.match_id,
    roundId: event.round_id,
    eventType: event.type,
    eventId: event.id,
    ...(event.type === 'kill'
      ? {
          damageItem: event.finishing_damage.damage_item,
          damageType: event.finishing_damage.damage_type,
        }
      : null),
  }),
  agent: agent => ({
    agentId: agent.id,
    agentName: agent.name,
  }),
  match_id: matchId => ({ matchId }),
  matchId: matchId => ({ matchId }),
  scrim_id: scrimId => ({ scrimId }),
  scrimId: scrimId => ({ scrimId }),
  roundNum: roundNum => ({ roundNum }),
  round_num: roundNum => ({ roundNum }),
  type: type => ({ type }),
  inferenceClass: inferenceClass => ({ inferenceClass }),
  agentMlName: agentMlName => ({ agentMlName }),
}

const prepareTags = params => {
  const tags = {}
  for (let key in params) {
    if (!Object.prototype.hasOwnProperty.call(params, key)) {
      continue
    }
    if (key in PARAM_TO_TAG) {
      Object.assign(tags, PARAM_TO_TAG[key](params[key]))
    } else {
      console.warn('unhandled param for tags', key)
    }
  }
  return tags
}

export default function reportException(msg, params) {
  if (!(msg instanceof Error)) {
    msg = new Error(msg)
  }
  const tags = prepareTags(params)
  console.debug(msg, tags)
  Sentry.captureException(msg, { tags })
}
