import axios from '@/axios.js'
import { normalizeApiTeams } from '@/utils/apiUtils.js'

import agentCompositionToApi from '../utils/agentCompositionToApi.js'
import { periodToApi } from '../utils/period.js'

/**
 * @param {API_AND_OR_OPTION} agent_option
 * @param {API_AND_OR_OPERATOR} agent_opr
 * @param {Array<Array<string>>} agent_comps
 * @param {Array<string>} agent_ids
 * @param {API_AND_OR_OPTION} agent_vs_option
 * @param {API_AND_OR_OPERATOR} agent_vs_opr
 * @param {Array<Array<string>>} agent_vs_comps
 * @param {Array<string>} agent_vs_ids
 * @param {boolean} has_vod
 * @param {Array<string>} map_ids
 * @param {Array<API_GAMIN_PLATFORM>} platforms
 * @param {string|null} begin_time
 * @param {string|null} end_time
 * @param {PACKED_PERIOD|UNPACKED_PERIOD} period
 * @param {Array<{id: string}>} players
 * @param {Array<string>} player_ids
 * @param {string} player_id
 * @param {API_AND_OR_OPTION} players_option
 * @param {API_AND_OR_OPTION} player_option
 * @param {API_AND_OR_OPERATOR} player_opr
 * @param {'created'|'rounds_played'|'rounds_won_diff'} sortBy
 * @param {'created'|'rounds_played'|'rounds_won_diff'} sort_by
 * @param {boolean} sortDesc
 * @param {boolean} sort_desc
 * @param {Array<{id: string}>} teams
 * @param {Array<string>} team_ids
 * @param {Array<{id: string}>} teams_vs
 * @param {Array<string>} vs_team_ids
 * @param {API_AND_OR_OPTION} teams_option
 * @param {Array<{id: string}>} teams_players
 * @param {Array<string>} teams_players_ids
 * @param {Array<string>} player_vs_ids
 * @param {API_AND_OR_OPTION} teams_players_option
 * @param {API_AND_OR_OPTION} player_vs_option
 * @param {API_AND_OR_OPERATOR} player_vs_opr
 * @param {Array<string>} event_ids
 * @param {object} rest
 * @param cancelToken
 * @return {Promise<{total: number, matches: API_SEARCH_MATCH[]}>}
 */
export const findMatches = async (
  {
    agent_option = 'or',
    agent_comps = [],
    agent_ids = [],
    agents_and = agent_option === 'and' ? agent_ids : [],
    agents_or = agent_option === 'or' ? agent_ids : [],

    agent_vs_option = 'or',
    agent_vs_comps = [],
    agent_vs_ids = [],
    vs_agents_and = agent_vs_option === 'and' ? agent_vs_ids : [],
    vs_agents_or = agent_vs_option === 'or' ? agent_vs_ids : [],

    has_vod,
    map_ids = [],
    platforms = [],

    begin_time = null,
    end_time = null,
    period = { begin_time, end_time },

    players = [],
    player_ids = players.map(player => player.id),
    players_option = 'or',
    players_and = players_option === 'and' ? player_ids : [],
    players_or = players_option === 'or' ? player_ids : [],

    sortBy = 'created',
    sort_by = sortBy,
    sortDesc = true,
    sort_desc = sortDesc,

    // teams
    teams = [],
    teams_vs = [],
    team_ids = teams.map(team => team.id),
    vs_teams = teams_vs.map(team => team.id),
    teams_option = 'or',

    teams_players = [],
    teams_players_ids = teams_players.map(player => player.id),
    player_vs_ids = teams_players_ids,
    teams_players_option = 'or',
    player_vs_option = teams_players_option,
    player_vs_opr = player_vs_option === 'and' ? '&' : '|',

    events = [],
    event_ids = events.map(event => event.id),

    count = 10,
    index = 1,
    limit = count,
    offset = (index - 1) * limit,

    ...rest
  } = {},
  { cancelToken } = {}
) => {
  // agent_comps is faster than agent_ids - use it in case full comp is provided
  // disabled as ordering of agent compositions must match backend ordering
  // if (agent_opr === '&' && agent_ids.length === 5) {
  //   agent_comps.push(agent_ids)
  //   agent_ids = []
  // }
  // if (agent_vs_opr === '&' && agent_vs_ids.length === 5) {
  //   agent_vs_comps.push(agent_ids)
  //   agent_vs_ids = []
  // }

  // searching for both teams - put one team in each filter
  if (teams_option === 'and' && vs_teams.length === 0) {
    vs_teams = team_ids.splice(1)
  }

  // console.log('api search', params);
  const query = {
    ...rest,
    agents_and,
    agents_or,
    vs_agents_and,
    vs_agents_or,
    agent_comps: agent_comps ? agent_comps.map(agentCompositionToApi) : [],
    vs_agent_comps: agent_vs_comps ? agent_vs_comps.map(agentCompositionToApi) : [],

    // fix has_vod to be null instead of false, as we want to disable the filter not show only without vod
    is_replay_processed: has_vod ? true : null,
    maps: map_ids,
    ...periodToApi(period),
    platforms,

    // new api
    players_and,
    players_or,
    player_vs_ids,
    player_vs_opr,

    // new api
    order_by: (sort_desc ? '-' : '') + sort_by,

    // teams
    teams: team_ids,
    vs_teams,

    events: event_ids,

    limit,
    offset,
  }

  const response = await axios.get('/val/v3/esport/matches', {
    params: query,
    cancelToken,
  })
  return {
    matches: ((response.data || []).esport_matches || []).map(match => {
      const teams = normalizeApiTeams(match)
      const [winner] = teams.filter(team => team.won)
      const [loser] = teams.filter(team => !team.won)
      let first_team, second_team
      let status = null

      if (!winner || !loser) {
        ;[first_team, second_team] = teams

        status = match.rounds_played < 24 ? 'Cancelled' : 'Limited Data'
      } else {
        first_team = winner
        second_team = loser
      }

      return {
        ...match,
        vod_status: match.replay_cropper_status,
        teams: teams,
        status,
        first_team,
        second_team,
      }
    }),
    total: response.data?.count || 0,
  }
}

/**
 *
 * @param {string} name - min 1 character
 * @param {number} count
 * @param {number} index
 * @param cancelToken
 * @return {Promise<{total: number, data: API_SEARCH_TEAM[]}>}
 */
export const findTeams = async ({ name, count = 20, index = 1 }, { cancelToken } = {}) => {
  const response = await axios.get('/val/v2/teams', {
    cancelToken,
    params: {
      name,
      count,
      index,
    },
  })
  return response.data
}

export const findCollegiateMatches = async (
  {
    agent_option = 'or',
    agent_comps = [],
    agent_ids = [],
    agents_and = agent_option === 'and' ? agent_ids : [],
    agents_or = agent_option === 'or' ? agent_ids : [],

    agent_vs_option = 'or',
    agent_vs_comps = [],
    agent_vs_ids = [],
    vs_agents_and = agent_vs_option === 'and' ? agent_vs_ids : [],
    vs_agents_or = agent_vs_option === 'or' ? agent_vs_ids : [],

    has_vod,
    map_ids = [],
    platforms = [],

    begin_time = null,
    end_time = null,
    period = { begin_time, end_time },

    players = [],
    player_ids = players.map(player => player.id),
    players_option = 'or',
    players_and = players_option === 'and' ? player_ids : [],
    players_or = players_option === 'or' ? player_ids : [],

    sortBy = 'created',
    sort_by = sortBy,
    sortDesc = true,
    sort_desc = sortDesc,

    // teams
    teams = [],
    teams_vs = [],
    team_ids = teams.map(team => team.id),
    vs_teams = teams_vs.map(team => team.id),
    teams_option = 'or',

    teams_players = [],
    teams_players_ids = teams_players.map(player => player.id),
    player_vs_ids = teams_players_ids,
    teams_players_option = 'or',
    player_vs_option = teams_players_option,
    player_vs_opr = player_vs_option === 'and' ? '&' : '|',

    events = [],
    event_ids = events.map(event => event.id),

    count = 10,
    index = 1,
    limit = count,
    offset = (index - 1) * limit,

    ...rest
  } = {},
  { cancelToken } = {}
) => {
  // searching for both teams - put one team in each filter
  if (teams_option === 'and' && vs_teams.length === 0) {
    vs_teams = team_ids.splice(1)
  }

  // console.log('api search', params);
  const query = {
    ...rest,
    agents_and,
    agents_or,
    vs_agents_and,
    vs_agents_or,
    agent_comps: agent_comps ? agent_comps.map(agentCompositionToApi) : [],
    vs_agent_comps: agent_vs_comps ? agent_vs_comps.map(agentCompositionToApi) : [],

    // fix has_vod to be null instead of false, as we want to disable the filter not show only without vod
    is_replay_processed: has_vod ? true : null,
    maps: map_ids,
    ...periodToApi(period),
    platforms,

    // new api
    players_and,
    players_or,
    player_vs_ids,
    player_vs_opr,

    // new api
    order_by: (sort_desc ? '-' : '') + sort_by,

    // teams
    teams: team_ids,
    vs_teams,

    events: event_ids,

    limit,
    offset,
  }

  const response = await axios.get('/val/v3/collegiate/matches', {
    params: query,
    cancelToken,
  })
  return {
    matches: ((response.data || []).collegiate_matches || []).map(match => {
      const teams = normalizeApiTeams(match)
      const [winner] = teams.filter(team => team.won)
      const [loser] = teams.filter(team => !team.won)
      let first_team, second_team
      let status = null

      if (!winner || !loser) {
        ;[first_team, second_team] = teams

        status = match.rounds_played < 24 ? 'Cancelled' : 'Limited Data'
      } else {
        first_team = winner
        second_team = loser
      }

      return {
        ...match,
        vod_status: match.replay_cropper_status,
        teams: teams,
        status,
        first_team,
        second_team,
      }
    }),
    total: response.data?.count || 0,
  }
}
