import { normalizeApiTeams } from '@/utils/apiUtils.js'

import axios from '../../axios.js'

import { getMatchAnalyticsPositions } from './analytics.js'

/**
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<API_MATCH_INFO>}
 */
export const getMatchInfo = async ({ matchId, isCollegiate = false }, { cancelToken } = {}) => {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const response = await axios.get(`/val/v3/${type}/matches/${matchId}`, { cancelToken })
  return {
    ...response.data,
    teams: normalizeApiTeams(response.data),
  }
}

/**
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<API_MATCH_SUMMARY>}
 */
// TODO: remove since not used anymore
export const getMatchSummary = async (matchId, { cancelToken } = {}) => {
  const response = await axios.get(`/val/v2/matches/${matchId}/summary`, { cancelToken })
  return response.data
}

/**
 * @param {string} matchId
 * @param cancelToken
 * @return {Promise<API_VOD_INFO>}
 */
// TODO: remove since not used anymore
export const getMatchVodInfo = async (matchId, { cancelToken }) => {
  const { vod } = getMatchAnalyticsPositions(matchId, { cancelToken })
  return vod
}

// TODO: remove since not used anymore
export const updateMatchVod = async (matchId, { vod_url, start_time, end_time }) => {
  const response = await axios.post(`/val/v2/matches/${matchId}/vods`, {
    vod_url,
    start_time,
    end_time,
  })
  return response.data
}

export const getMatchRiotData = async ({ matchId, isCollegiate = false }, { cancelToken } = {}) => {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const response = await axios.get(`/val/v3/${type}/matches/${matchId}/data/riot/parsed`, { cancelToken })
  if (response.data.detail) {
    throw new Error(response.data.detail)
  }
  return response.data
}

export const getMatchReplayInfo = async ({ matchId, isCollegiate = false }, { cancelToken } = {}) => {
  try {
    const type = isCollegiate ? 'collegiate' : 'esport'
    const response = await axios.get(`/val/v3/${type}/matches/${matchId}/replay`, { cancelToken })
    if (response.data.detail) {
      throw new Error(response.data.detail)
    }
    return response.data
  } catch (error) {
    if (error.response?.status === 404) {
      return null
    }
    throw error
  }
}

export const uploadMatchReplay = async ({
  matchId,
  videoId,
  videoPlatform,
  videoStartTime,
  videoEndTime,
  isCollegiate,
}) => {
  const type = isCollegiate ? 'collegiate' : 'esport'
  const response = await axios.post(`/val/v3/${type}/matches/${matchId}/replay`, {
    video_platform: videoPlatform,
    video_id: videoId,
    video_start_time: videoStartTime,
    video_end_time: videoEndTime,
  })
  if (response.data.detail) {
    throw new Error(response.data.detail)
  }
  return response.data
}
