export default function groupBy(list, mapper) {
  return list.reduce((grouped, item) => {
    const key = mapper(item)
    if (!(key in grouped)) {
      grouped[key] = []
    }
    grouped[key].push(item)
    return grouped
  }, {})
}
