import Vue from 'vue'
import VueRouter from 'vue-router'

import ErrorFallback from '@/pages/ErrorFallback.vue'
import { redirectToSSO, sessionKeyExpired } from '@/utils/sso'

import mixpanel from './mixpanel.js'
import store from './store/index.js'

Vue.use(VueRouter)

function safeImport(importer) {
  return async () => {
    try {
      return await importer()
    } catch (e) {
      console.info('reloading page due to error loading component', e)
      return ErrorFallback
    }
  }
}

const routes =
  process.env.VUE_APP_VARIANT === 'overwolf'
    ? [
        {
          path: '*',
          name: 'ow',
          component: safeImport(() => import('./pages/OverwolfMap.vue')),
          meta: {
            layout: 'ow',
            public: true,
            auth: 'public',
            title: '2D Map',
          },
        },
      ]
    : [
        {
          path: '/',
          redirect: (...args) => {
            console.log('router root', this, args)
            if (store.getters['auth/can_access_public_matches']) {
              return '/matches'
            }
            if (store.getters['auth/can_access_collegiate_matches']) {
              return '/collegiate/matches'
            }
            if (store.getters['auth/can_access_grid_emea_matches']) {
              return '/grid/matches'
            }
          },
        },

        {
          path: '/matches',
          name: 'Matches',
          component: safeImport(() => import('./pages/Matches.vue')),
          props: to => to.query,
        },

        {
          path: '/team/:team_id',
          name: 'Team',
          component: safeImport(() => import('./pages/TeamPage.vue')),
          props: to => ({ ...to.query, ...to.params }),
          children: [
            {
              name: 'TeamStats',
              path: 'stats/:map_id',
              components: {
                team: safeImport(() => import(/* webpackChunkName: "team-map" */ './views/TeamNew/TeamStatsMain.vue')),
              },
            },
            {
              // the name is used in App.vue to bypass common team/map check for 2d map
              name: 'TeamMatches',
              path: 'matches/:map_id',
              components: {
                team: safeImport(() => import(/* webpackChunkName: "team-matches" */ './views/TeamNew/Matches.vue')),
              },
            },
            {
              name: 'TeamReports',
              path: 'reports/:map_id',
              components: {
                team: safeImport(() => import(/* webpackChunkName: "team-reports" */ './views/TeamNew/Reports.vue')),
              },
            },
          ],
        },

        // {
        //   name: 'TeamReports',
        //   path: '/team/:team_id/reports/:map_id',
        //   component: safeImport(() => import('./views/Team/Reports.vue')),
        // },

        // Teams
        {
          path: '/teams',
          name: 'Teams',
          component: safeImport(() => import('./pages/Teams.vue')),
          props: route => ({ s: route.query.s }),
        },

        // Scrims
        // {
        //   path: '/scrims',
        //   name: 'SearchScrims',
        //   component: safeImport(() => import('./pages/scrims/SearchScrims.vue')),
        //   props: route => ({ s: route.query.s }),
        // },
        // {
        //   path: '/scrims/new',
        //   name: 'NewScrim',
        //   component: safeImport(() => import('./pages/scrims/NewScrim.vue')),
        // },
        // {
        //   path: '/scrims/stats',
        //   name: 'ScrimTeamStats',
        //   component: safeImport(() => import('./pages/scrims/ScrimTeamStatsPage.vue')),
        // },
        // {
        //   path: '/scrim/:scrim_id/edit',
        //   name: 'EditScrim',
        //   component: safeImport(() => import('./pages/scrims/ScrimEditPage.vue')),
        //   props: route => ({
        //     scrimId: route.params.scrim_id,
        //   }),
        // },
        // {
        //   path: '/scrim/:scrim_id',
        //   component: safeImport(() => import('./pages/scrims/ScrimPage.vue')),
        //   props: route => ({
        //     scrimId: route.params.scrim_id,
        //     roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
        //   }),
        //   children: [
        //     {
        //       path: '',
        //       redirect: 'overview',
        //     },
        //     {
        //       path: 'overview',
        //       name: 'Overview',
        //       components: {
        //         content: safeImport(() => import('./pages/scrims/ScrimOverviewPage.vue')),
        //       },
        //       props: {
        //         content: route => ({
        //           scrimId: route.params.scrim_id,
        //         }),
        //       },
        //     },
        //     { path: 'overview/:round', redirect: 'overview' },
        //     {
        //       path: 'economy',
        //       name: 'Economy',
        //       components: {
        //         content: safeImport(() => import('./pages/scrims/ScrimEconomyPage.vue')),
        //       },
        //       props: {
        //         content: route => ({
        //           scrimId: route.params.scrim_id,
        //         }),
        //       },
        //     },
        //     { path: 'economy/:round', redirect: 'economy' },
        //     {
        //       path: 'performance',
        //       name: 'Performance',
        //       components: {
        //         content: safeImport(() => import('./pages/scrims/ScrimPerformancePage.vue')),
        //       },
        //       props: {
        //         content: route => ({
        //           scrimId: route.params.scrim_id,
        //         }),
        //       },
        //     },
        //     { path: 'performance/:round', redirect: 'performance' },
        //     {
        //       path: 'notes',
        //       name: 'Notes',
        //       components: {
        //         content: safeImport(() => import('./pages/scrims/ScrimNotesPage.vue')),
        //       },
        //       props: {
        //         content: route => ({
        //           scrimId: route.params.scrim_id,
        //         }),
        //       },
        //     },
        //     { path: 'notes/:round', redirect: 'notes' },
        //   ],
        // },
        // {
        //   path: '/scrim/:scrims/map',
        //   name: 'ScrimMapTool',
        //   component: safeImport(() => import('./pages/scrims/ScrimMapToolPage.vue')),
        //   props: route => {
        //     return {
        //       scrims: route.params.scrims.split('.'),
        //       s: route.query.s,
        //     }
        //   },
        //   meta: {
        //     layout: 'v3dafi',
        //   },
        // },

        // Scrims 2 routes
        {
          path: '/scrims2',
          name: 'Scrims2List',
          component: safeImport(() => import('./pages/scrims2/Scrims2ListPage.vue')),
          meta: {
            auth: 'sso',
          },
        },
        {
          path: '/scrims2/map/:id',
          name: 'Scrims2Map',
          component: safeImport(() => import('./pages/OverwolfMap.vue')),
          props: route => ({
            id: route.params.id,
            initialState: route.query.state,
          }),
          meta: {
            auth: 'sso',
            layout: 'v3dafi',
          },
        },
        {
          path: '/scrim-stats',
          name: 'ScrimStats',
          component: safeImport(() => import('./pages/ScrimStats.vue')),
          meta: {
            auth: 'sso',
          },
        },
        {
          path: '/grid/map/:id',
          name: 'GridMapTool',
          component: safeImport(() => import('./pages/grid/GridMapPage.vue')),
          props: route => ({
            ids: [route.params.id],
            apiKey: route.query.key || store.getters['auth/grid_key'],
            localCache: route.query.localCache != null,
            initialState: route.query.state,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },
        {
          path: '/grid/map/:id/:map',
          name: 'GridMapToolMap',
          component: safeImport(() => import('./pages/grid/GridMapPage.vue')),
          props: route => ({
            ids: route.params.id.split(','),
            apiKey: route.query.key || store.getters['auth/grid_key'],
            localCache: route.query.localCache != null,
            mapName: route.params.map.toLowerCase(),
            pos: route.query.pos,
            abu: route.query.abu,
            initialState: route.query.state,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },
        {
          path: '/grid/live/:id',
          name: 'GridLiveMap',
          component: safeImport(() => import('./pages/grid/GridLiveMapPage.vue')),
          props: route => ({
            id: Number.parseInt(route.params.id, 10),
            apiKey: route.query.key || store.getters['auth/grid_key'],
            initialState: route.query.state,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },
        {
          path: '/grid/live2/:id',
          name: 'GridLive2Map',
          component: safeImport(() => import('./pages/grid/GridLive2MapPage.vue')),
          props: route => ({
            id: Number.parseInt(route.params.id, 10),
            initialState: route.query.state,
            pos: route.query.pos,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },
        {
          path: '/grid/live2/:id/:map',
          name: 'GridLive2Map',
          component: safeImport(() => import('./pages/grid/GridLive2MapPage.vue')),
          props: route => ({
            id: Number.parseInt(route.params.id, 10),
            initialState: route.query.state,
            mapName: route.params.map.toLowerCase(),
            pos: route.query.pos,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },

        //Grid scrims
        {
          path: '/grid/scrims',
          name: 'GridScrims',
          component: safeImport(() => import('./pages/grid/GridMatchesListPage.vue')),
          props: route => ({
            manualKey: route.query.key,
            feed: 'SCRIM',
            past: true,
            current: true,
            future: true,
          }),
          meta: {
            grid: true,
          },
        },

        //Grid matches
        {
          path: '/grid/matches',
          name: 'GridMatches',
          component: safeImport(() => import('./pages/grid/GridMatchesListPage.vue')),
          props: route => ({
            ...route.query,
            manualKey: route.query.key,
            past: true,
            current: true,
            future: false,
            feed: 'ESPORTS',
          }),
          meta: {
            grid: true,
          },
        },

        {
          path: '/grid/matches/loop',
          name: 'GridMatches',
          component: safeImport(() => import('./pages/grid/GridMatchesListPage.vue')),
          props: route => ({
            manualKey: route.query.key,
            past: true,
            current: true,
            future: false,
            feed: 'LOOPFEED',
          }),
          meta: {
            grid: true,
          },
        },

        {
          path: '/grid/matches/upcoming',
          name: 'GridUpcomingMatches',
          component: safeImport(() => import('./pages/grid/GridMatchesListPage.vue')),
          props: route => ({
            manualKey: route.query.key,
            past: false,
            current: true,
            future: true,
            feed: 'ESPORTS',
          }),
          meta: {
            grid: true,
          },
        },

        {
          path: '/grid/key',
          name: 'RequestGridKey',
          component: safeImport(() => import('./pages/grid/RequestGridKeyPage.vue')),
          props: route => ({
            redirect: route.query.redirect,
          }),
        },

        {
          path: '/grid/match/:series_id/:game_id',
          component: safeImport(() => import('./pages/grid/GridMatchPage.vue')),
          props: route => ({
            seriesId: route.params.series_id,
            gameId: route.params.game_id,
            manualKey: route.query.key,
          }),
          children: [
            {
              path: '',
              redirect: 'overview',
            },
            {
              path: 'overview',
              name: 'GridOverview',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "grid-match-overview" */ './components/grid/match/Overview.vue')
                ),
              },
            },
            { path: 'overview/:round', redirect: 'overview' },
            {
              path: 'economy',
              name: 'GridEconomy',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "grid-match-overview" */ './components/grid/match/Economy.vue')
                ),
              },
            },
            { path: 'economy/:round', redirect: 'economy' },
            {
              path: 'performance',
              name: 'GridPerformance',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "grid-match-overview" */ './components/grid/match/Performance.vue')
                ),
              },
            },
            { path: 'performance/:round', redirect: 'performance' },
          ],
        },

        {
          path: '/test/:map',
          component: safeImport(() => import('./components/Map/MapController.vue')),
          props: to => ({
            data: {
              map: store.getters['static/mapsByNameLowecase'][to.params.map.toLowerCase()],
              matches: {},
              rounds: {},
              teams: {},
            },
            map: store.getters['static/mapsByNameLowecase'][to.params.map.toLowerCase()].id,
          }),
          meta: {
            layout: 'v3dafi',
            public: true,
          },
        },

        // Match Routes
        {
          // the param is used in App.vue to bypass common team/map check for 2d map
          path: '/match/:match_id',
          component: safeImport(() => import('./views/Match.vue')),
          props: route => ({
            matchId: route.params.match_id,
            roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
          }),
          children: [
            {
              path: '',
              redirect: 'overview',
            },
            {
              path: 'overview',
              name: 'Overview',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-overview" */ './components/Match.old/Overview.vue')
                ),
              },
            },
            { path: 'overview/:round', redirect: 'overview' },
            {
              path: 'economy',
              name: 'Economy',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-economy" */ './components/Match.old/Economy.vue')
                ),
              },
            },
            { path: 'economy/:round', redirect: 'economy' },
            {
              path: 'performance',
              name: 'Performance',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-performance" */ './components/Match.old/Performance.vue')
                ),
              },
              props: {
                match: true,
              },
            },
            { path: 'performance/:round', redirect: 'performance' },
            {
              path: 'spectator',
              name: 'Spectator',
              components: {
                match: safeImport(() => import('./pages/match/SpectatorVideo.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            {
              path: 'round',
              name: 'Round',
              components: {
                match: safeImport(() => import('./pages/match/RoundVideoAndStats.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            {
              path: 'notes',
              name: 'Notes',
              components: {
                match: safeImport(() => import('./components/Match.old/NotesPage.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            { path: 'notes/:round', redirect: 'notes' },
          ],
        },

        // Map Routes
        {
          path: '/map/:map_name/:team_id',
          name: 'Map',
          component: safeImport(() => import(/* webpackChunkName: "map" */ './views/Map.vue')),
          props: route => ({
            initialState: route.query.state,
          }),
          meta: {
            layout: 'v3dafi',
          },
        },

        {
          path: '/proto-map/:map_name/:team_id',
          name: 'ProtoMap',
          component: safeImport(() => import(/* webpackChunkName: "map" */ './views/ProtoMap.vue')),
          props: route => ({
            initialState: route.query.state,
          }),
          meta: {
            layout: 'v3proto',
          },
        },

        // Player routes
        {
          path: '/player/:player_id',
          name: 'Player',
          component: safeImport(() => import(/* webpackChunkName: "player" */ './views/Player.vue')),
          children: [
            {
              name: 'PlayerStats',
              path: 'stats/:map_id',
              components: {
                player: safeImport(() => import(/* webpackChunkName: "player-map" */ './views/Player/Stats.vue')),
              },
            },
            {
              name: 'PlayerMatches',
              path: 'matches/:map_id/:index/:count',
              components: {
                player: safeImport(() => import(/* webpackChunkName: "player-matches" */ './views/Player/Matches.vue')),
              },
            },
          ],
        },

        // Auth public routes
        {
          path: '/login',
          name: 'Login',
          meta: {
            public: true,
          },
          beforeEnter(to, from, next) {
            if (sessionKeyExpired()) {
              redirectToSSO('login', { redirect: to.query.redirect || from?.fullPath })
            } else {
              next(to.query.redirect || from)
            }
          },
        },
        {
          path: '/register',
          name: 'Register',
          meta: {
            public: true,
          },
          beforeEnter(to, from, next) {
            if (sessionKeyExpired()) {
              redirectToSSO('register', { redirect: to.query.redirect || from?.fullPath })
            } else {
              next(to.query.redirect || from)
            }
          },
        },
        {
          path: '/recover-password',
          name: 'RecoverPassword',
          meta: {
            public: true,
          },
          beforeEnter(to, from, next) {
            if (sessionKeyExpired()) {
              redirectToSSO('password', { redirect: to.query.redirect || from?.fullPath })
            } else {
              next(to.query.redirect || from)
            }
          },
        },

        // User Routes
        {
          path: '/payment',
          name: 'Payment',
          beforeEnter() {
            window.location.href = 'https://shop.augment.gg'
          },
        },
        {
          path: '/profile',
          name: 'Profile',
          beforeEnter() {
            window.location.href = 'https://sso.augment.gg'
          },
        },

        // Billing routes
        {
          path: '/billing',
          name: 'Billing',
          beforeEnter() {
            window.location.href = 'https://shop.augment.gg'
          },
        },

        // Static Pages Routes
        {
          path: '/terms-and-conditions',
          name: 'TermsAndConditions',
          component: safeImport(() =>
            import(/* webpackChunkName: "terms-and-conditions" */ './views/TermsAndConditions.vue')
          ),
        },
        {
          path: '/privacy-policy',
          name: 'PrivacyPolicy',
          component: safeImport(() => import(/* webpackChunkName: "privacy-policy" */ './views/PrivacyPolicy.vue')),
        },

        {
          path: '/help',
          name: 'Help',
          component: safeImport(() => import('./pages/HelpPage.vue')),
        },
        {
          path: '/feedback',
          name: 'Feedback',
          component: safeImport(() => import('./pages/Feedback.vue')),
        },
        {
          path: '/debug',
          name: 'Debug',
          component: safeImport(() => import('./pages/Debug.vue')),
        },

        // Collegiate routes
        {
          path: '/collegiate/matches',
          name: 'CollegiateMatches',
          component: safeImport(() => import('./pages/collegiate/CollegiateMatchesPage.vue')),
          props: to => to.query,
        },

        // Collegiate map routes
        {
          path: '/collegiate/map/:map_name/:team_id',
          name: 'Map',
          component: safeImport(() => import(/* webpackChunkName: "map" */ './views/Map.vue')),
          props: to => ({ ...to.query, isCollegiate: true, initialState: to.query.state }),
        },

        // Collegiate Match Routes
        {
          // the param is used in App.vue to bypass common team/map check for 2d map
          path: '/collegiate/match/:match_id',
          component: safeImport(() => import('./views/Match.vue')),
          props: route => {
            route.params.isCollegiate = true
            return {
              matchId: route.params.match_id,
              roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
            }
          },
          children: [
            {
              path: '',
              redirect: 'overview',
            },
            {
              path: 'overview',
              name: 'Overview',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-overview" */ './components/Match.old/Overview.vue')
                ),
              },
            },
            { path: 'overview/:round', redirect: 'overview' },
            {
              path: 'economy',
              name: 'Economy',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-economy" */ './components/Match.old/Economy.vue')
                ),
              },
            },
            { path: 'economy/:round', redirect: 'economy' },
            {
              path: 'performance',
              name: 'Performance',
              components: {
                match: safeImport(() =>
                  import(/* webpackChunkName: "match-performance" */ './components/Match.old/Performance.vue')
                ),
              },
              props: {
                match: true,
              },
            },
            { path: 'performance/:round', redirect: 'performance' },
            {
              path: 'spectator',
              name: 'Spectator',
              components: {
                match: safeImport(() => import('./pages/match/SpectatorVideo.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            {
              path: 'round',
              name: 'Round',
              components: {
                match: safeImport(() => import('./pages/match/RoundVideoAndStats.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            {
              path: 'notes',
              name: 'Notes',
              components: {
                match: safeImport(() => import('./components/Match.old/NotesPage.vue')),
              },
              props: {
                match: route => ({
                  matchId: route.params.match_id,
                  roundNum: route.query.round != null ? parseInt(route.query.round, 10) : null,
                }),
              },
            },
            { path: 'notes/:round', redirect: 'notes' },
          ],
        },
      ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

if (process.env.VUE_APP_VARIANT !== 'overwolf') {
  router.beforeEach((to, from, next) => {
    if (!to.meta.auth) {
      to.meta.auth = to.meta.public ? 'public' : 'sso'
    }
    switch (to.meta.auth) {
      case 'public':
        break
      case 'old':
        if (!store.getters['auth/is_authenticated']) {
          next({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          })
          return
        }
        break
      case 'sso':
        if (sessionKeyExpired()) {
          redirectToSSO('login', { redirect: to.fullPath })
          return
        }
        break
      default:
        throw new Error(`Invalid auth type ${to.meta.auth}`)
    }

    // const { match_id } = to.params
    // if (match_id) {
    //   store.dispatch('match/get_match', to.params.match_id)
    // }

    if (to.meta.grid) {
      const canAccess = store.getters['auth/can_access_grid']
      const gridKey = store.getters['auth/grid_key']

      if (!canAccess && !gridKey) {
        next({
          path: '/grid/key',
          query: {
            redirect: to.fullPath,
          },
        })
        return
      }
    }

    mixpanel.track_router(to.name, to.fullPath)

    next()
  })
}

export default router
