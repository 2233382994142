import * as Sentry from '@sentry/vue'
import { GesturePlugin } from '@vueuse/gesture'
import {
  BIcon,
  BIconArrowRepeat,
  BIconCameraVideoFill,
  BIconCameraVideoOffFill,
  BIconCloudArrowDownFill,
  BIconCloudArrowUpFill,
  BIconCpuFill,
  BIconLayersFill,
  ButtonGroupPlugin,
  ButtonPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  LayoutPlugin,
  NavbarPlugin,
  NavPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  TablePlugin,
  TooltipPlugin,
  VBToggle,
} from 'bootstrap-vue'
import mixpanel from 'mixpanel-browser'
import PortalVue from 'portal-vue'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
import VueHotjar from 'vue-hotjar'

import { getBackend } from '@/axios'

import router from './router.js'
import store from './store/index.js'

import './filters/dates.js'
import './filters/currency.js'

// TODO Optimise bootstrap-vue style imports
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './App.scss'

Vue.use(VueHotjar, {
  id: '5042819',
  isProduction: true,
  snippetVersion: 6,
})

// Add additional plugins if needed
Vue.use(ButtonGroupPlugin)
Vue.use(ButtonPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(FormSelectPlugin)
Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(NavPlugin)
Vue.use(PaginationPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TooltipPlugin)
Vue.use(VBToggle)
Vue.use(PortalVue)
Vue.use(GesturePlugin)

// Add additional icon components if needed
Vue.component('BIcon', BIcon)
Vue.component('BIconArrowRepeat', BIconArrowRepeat)
Vue.component('BIconCameraVideoFill', BIconCameraVideoFill)
Vue.component('BIconCameraVideoOffFill', BIconCameraVideoOffFill)
Vue.component('BIconLayersFill', BIconLayersFill)
Vue.component('BIconCloudArrowDownFill', BIconCloudArrowDownFill)
Vue.component('BIconCloudArrowUpFill', BIconCloudArrowUpFill)
Vue.component('BIconCpuFill', BIconCpuFill)

Vue.directive('mask', VueMaskDirective)

Vue.mixin({
  computed: {
    hideBrokenStuff() {
      return process.env.VUE_APP_HIDE_BROKEN_STUFF === 'yes'
    },
  },
})

Vue.config.productionTip = false

const isInOverwolf = () => {
  return new URLSearchParams(window.location.search).has('overwolf')
}

if (process.env.VUE_APP_ERROR_TRACING === 'true') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracingOrigins: ['*.augment.gg', /^\//],
  })

  if (process.env.VUE_APP_VARIANT === 'overwolf') {
    import('@/utils/ow-sentry.js').then(({ postInitSentry }) => {
      postInitSentry()
    })
  }
}

if (process.env.VUE_APP_MIXPANEL_TOKEN) {
  if (isInOverwolf()) {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_OW_TOKEN)
  } else {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)
  }
  mixpanel.register({
    backend_url: getBackend(),
    frontend_version: process.env.VUE_APP_VERSION,
  })
}

const init = async target => {
  if (process.env.VUE_APP_VARIANT !== 'overwolf') {
    const query = new URLSearchParams(location.search)
    if (query.has('sessionKey')) {
      await store.dispatch('auth/init_from_sso', { sessionKey: query.get('sessionKey') })
      const newUrl = new URL(location.href)
      newUrl.searchParams.delete('sessionKey')
      history.replaceState(null, null, newUrl.toString())
    }

    await Promise.all([store.dispatch('auth/init_store'), store.dispatch('static/get_assets')])
  } else {
    await Promise.all([store.dispatch('static/get_assets')])
  }

  const { default: App } = await import('./App.vue')

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount(target)
}

init('#app')
