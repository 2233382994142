import axios from '../axios.js'

const PRELOAD_STATIC_ASSETS = process.env.VUE_APP_PRELOAD_STATIC_ASSETS === 'true'
const ASSETS_API_URL = process.env.VUE_APP_ASSETS_API_URL || null

export const getAssets = async ({ cancelToken } = {}) => {
  const response = await axios.get(PRELOAD_STATIC_ASSETS ? '/assets.json' : '/val/v3/static/assets', {
    ...(ASSETS_API_URL ? { baseURL: '/api' } : {}),
    cancelToken,
  })
  return response.data
}

export const getTeamAbbrs = async ({ cancelToken } = {}) => {
  const response = await axios.get(PRELOAD_STATIC_ASSETS ? '/team-abbrs.json' : '/val/v3/esport/team-abbrs', {
    ...(ASSETS_API_URL ? { baseURL: '/api' } : {}),
    cancelToken,
  })
  return response.data
}
