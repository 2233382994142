import axios from '@/axios.js'
import mixpanel from '@/mixpanel'
import { normalizeApiTeams } from '@/utils/apiUtils.js'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../store/modules/search.js'

/**
 * @typedef {'match'|'team'} BOOKMARK_TYPE
 */

/**
 * @type {Object<BOOKMARK_TYPE, string>}
 */
const API_TYPE = {
  match: 'esport_matches',
  team: 'esport_teams',
}

/**
 * @param {BOOKMARK_TYPE} type
 * @return {string}
 */
function getApiType(type) {
  if (!(type in API_TYPE)) throw new Error(`Invalid bookmark type ${type}`)
  return API_TYPE[type]
}

/**
 * @param {BOOKMARK_TYPE} type
 * @param {string} id
 * @return {Promise<void>}
 */
export async function createBookmark({ type, id }) {
  await axios.post(
    '/val/v3/bookmarks',
    {
      type: getApiType(type),
      target_id: id,
    },
    {
      validateStatus: status => status < 300 || status === 404,
    }
  )
  mixpanel.track_create_bookmark({ type, id })
}

/**
 * @param {BOOKMARK_TYPE} type
 * @param {string} id
 * @return {Promise<void>}
 */
export async function deleteBookmark({ id }) {
  await axios.delete(`/val/v3/bookmarks/${id}`, {
    validateStatus: status => status < 300 || status === 404,
  })
  mixpanel.track_delete_bookmark({ id })
}

/**
 * @param {BOOKMARK_TYPE} type
 * @param {string} id
 * @return {Promise<{data: API_SEARCH_MATCH, total: number}>}
 */
export async function listBookmarks(
  { type, count = DEFAULT_ITEMS_PER_PAGE, index = DEFAULT_PAGE_INDEX },
  { cancelToken } = {}
) {
  let { data } = await axios.get('/val/v3/bookmarks', {
    cancelToken,
    params: {
      type: getApiType(type),
      limit: count,
      offset: count * (index - 1),
    },
  })
  data.bookmarks = data.bookmarks
    ?.filter(bookmark => bookmark.target_id)
    .map(bookmark => {
      return {
        ...bookmark,
        ...(bookmark?.target_expand
          ? {
              ...bookmark.target_expand,
              teams: normalizeApiTeams(bookmark.target_expand),
            }
          : {}),
        bookmark: bookmark.id,
      }
    })
  mixpanel.track_list_bookmark({ type, count, index })
  return data || {}
}
