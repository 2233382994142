export const normalizeApiTeams = apiData => {
  return apiData?.teams_through.map(team => {
    return {
      ...team,
      abbr: team.team_expand.abbr,
      grid: team.team_side,
      logo_url: team.team_expand.logo_url,
      id: team.team_expand.id,
      name: team.team_expand.name,
      num_points: team.rounds_won,
      rounds_won: team.rounds_won,
      won: team.win,
    }
  })
}
