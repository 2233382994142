import * as Sentry from '@sentry/vue'

import { findMatches } from '../../api/search.js'
import { getTeamInfo, getTeamOverview, getTeamStats } from '../../api/teams.js'
import { deepFreeze } from '../../helper.js'

const init_team_state = {
  overview: null,
  stats: null,
  image: null,
  loading_overview: false,
  loading_stats: false,
  members: [],
  name: null,
  region: null,
  selected_map_id: null,
  server_error: null,
  win_conditions: [],
  weapon_stats: [],
  plant_performance: [],
  plant_performance_filters: {
    plants: [],
    scenarios: [],
  },
  has_map_data: false,
  performance_atk: [],
  performance_def: [],
}

export default {
  namespaced: true,

  state: init_team_state,

  mutations: {
    UPDATE_IMAGE: (state, data) => (state.image = data),
    UPDATE_LOADING_OVERVIEW: (state, data) => (state.loading_overview = data),
    UPDATE_LOADING_STATS: (state, data) => (state.loading_stats = data),
    UPDATE_MEMBERS: (state, data) => (state.members = data),
    UPDATE_NAME: (state, data) => (state.name = data),
    UPDATE_REGION: (state, data) => (state.region = data),
    UPDATE_SELECTED_MAP_ID: (state, map) => (state.selected_map_id = map),
    UPDATE_SERVER_ERROR: (state, error) => (state.server_error = error),
    UPDATE_WIN_CONDITIONS: (state, data) => (state.win_conditions = data),
    UPDATE_WEAPON_STATS: (state, data) => (state.weapon_stats = data),
    UPDATE_PLANT_PERFORMANCE: (state, data) => (state.plant_performance = data),
    UPDATE_PLANT_PERFORMANCE_FILTERS: (state, data) => (state.plant_performance_filters = data),
    UPDATE_HAS_MAP_DATA: (state, data) => (state.has_map_data = data),
    UPDATE_PERFORMANCE_ATK: (state, data) => (state.performance_atk = data),
    UPDATE_PERFORMANCE_DEF: (state, data) => (state.performance_def = data),

    UPDATE_STATS: (state, stats) => {
      state.stats = deepFreeze(stats)
    },
    UPDATE_OVERVIEW: (state, overview) => {
      state.overview = deepFreeze(overview)
    },
  },

  getters: {
    id: state => state.overview?.team_id,
    image: state => state.image,
    loading_overview: state => state.loading_overview,
    loading_stats: state => state.loading_stats,
    members: state => state.members,
    name: state => state.name,
    region: state => state.region,
    selected_map_id: state => state.selected_map_id,
    server_error: state => state.server_error,
    win_conditions: state => state.win_conditions,
    weapon_stats: state => state.weapon_stats,
    plant_performance: state => state.plant_performance,
    plant_performance_filter_plants: state => state.plant_performance_filters.plants,
    plant_performance_filter_scenarios: state => state.plant_performance_filters.scenarios,
    has_map_data: state => state.has_map_data,
    performance_atk: state => state.performance_atk,
    performance_def: state => state.performance_def,

    compositions: state => Object.freeze(state.overview?.agent_comps),
    detailed_performance: state =>
      deepFreeze(
        state.stats?.post_plants
          .filter(({ scenario }) => scenario)
          .map(post_plant => ({
            bombsite: post_plant.plant_site,
            scenario: post_plant.scenario,
            atk: {
              plants: post_plant.attacking.plants,
              wins: post_plant.attacking.wins,
              losses: post_plant.attacking.plants - post_plant.attacking.wins,
            },
            def: {
              plants: post_plant.defending.plants,
              wins: post_plant.defending.wins,
              losses: post_plant.defending.plants - post_plant.defending.wins,
            },
          }))
      ),
    detailed_performance_atk: (state, { detailed_performance }) =>
      Object.freeze(detailed_performance.map(({ atk, ...rest }) => ({ ...rest, ...atk })).map(Object.freeze)),
    detailed_performance_def: (state, { detailed_performance }) =>
      Object.freeze(detailed_performance.map(({ def, ...rest }) => ({ ...rest, ...def })).map(Object.freeze)),
    maps: state => state.overview?.maps,
  },

  actions: {
    update_selected_map_id({ commit }, map_id) {
      console.info(`%c team/update_selected_map_id( ${map_id} )`, 'color:#38bfb2')

      commit('UPDATE_SELECTED_MAP_ID', map_id)
    },

    get_team_overview({ commit }, id) {
      const req_info = getTeamInfo(id)
      const req_overview = getTeamOverview(id)

      console.info(`%c team/get_team_overview( ${id} )`, 'color:#38bfb2')

      commit('UPDATE_LOADING_OVERVIEW', true)
      commit('UPDATE_SERVER_ERROR', null)
      commit('UPDATE_IMAGE', init_team_state.image)
      commit('UPDATE_MEMBERS', init_team_state.members)
      commit('UPDATE_NAME', init_team_state.name)
      commit('UPDATE_REGION', init_team_state.region)

      return Promise.all([
        req_info,
        req_overview,
        // eslint-disable-next-line no-unused-vars
      ])
        .then(([info, overview]) => {
          commit('UPDATE_IMAGE', info.image)
          commit('UPDATE_LOADING_OVERVIEW', false)
          commit('UPDATE_MEMBERS', info.members)
          commit('UPDATE_NAME', info.name)
          commit('UPDATE_REGION', info.region)
          commit('UPDATE_OVERVIEW', { team_id: id, ...overview })
          return { info, overview }
        })
        .catch(error => {
          console.error(`Failure team/get_team_overview( ${id} )`, error)
          commit('UPDATE_LOADING_OVERVIEW', false)
          commit('UPDATE_SERVER_ERROR', error.response?.data.detail || error)
          Sentry.captureException(error)
        })
    },

    get_maps_stats(
      { commit, rootGetters: { 'static/getWeaponById': getWeaponById } },
      {
        map_id,
        map_ids = [map_id],

        platforms,

        compositions,
        agent_comps = compositions,

        has_vod,

        begin_time,
        end_time,
        period,

        teams = [],
        team_ids = teams.map(team => team.id),
        team_id = team_ids?.[0] || null,

        teams_vs = [],
        team_vs_ids = teams_vs.map(team => team.id),

        count,
        index,
      }
    ) {
      console.info(
        `%c team/get_maps_stats({ ${team_id}, ${map_ids}, ${agent_comps}, ${team_vs_ids}, ${begin_time}, ${end_time} })`,
        'color:#38bfb2'
      )

      commit('UPDATE_LOADING_STATS', true)
      commit('UPDATE_SERVER_ERROR', init_team_state.server_error)
      commit('UPDATE_PLANT_PERFORMANCE', init_team_state.plant_performance)
      commit('UPDATE_PLANT_PERFORMANCE_FILTERS', init_team_state.plant_performance_filters)
      commit('UPDATE_PERFORMANCE_ATK', init_team_state.performance_atk)
      commit('UPDATE_PERFORMANCE_DEF', init_team_state.performance_def)

      return getTeamStats(team_id, {
        map_ids,
        platforms,
        agent_comps,
        team_vs_ids,
        begin_time,
        end_time,
        period,
        has_vod,
        count,
        index,
      })
        .then(data => {
          commit('UPDATE_LOADING_STATS', false)
          commit('UPDATE_STATS', { team_id, map_id, ...data })

          const {
            attack_speed: combined_attack_speed,
            // pistol_post_plants,
            post_plants: combined_post_plants,
            // total_matches,
            total_rounds,
            weapons,
            win_conditions: combined_win_conditions,
          } = data

          commit('UPDATE_HAS_MAP_DATA', !!total_rounds)

          if (!total_rounds) {
            return
          }

          // remove detailed stats by agent composition
          const attack_speed = combined_attack_speed.filter(({ agent_comp }) => agent_comp == null)
          const post_plants = combined_post_plants.filter(({ agent_comp }) => agent_comp == null)
          const win_conditions = combined_win_conditions.filter(({ agent_comp }) => agent_comp == null)

          // PLANT PERFORMANCE
          const scenarios = [
            { value: 'P', text: 'Pistol' },
            { value: '$', text: 'Eco 0-5k' },
            { value: '$$', text: 'Semi-eco 5-10k' },
            { value: '$$$', text: 'Semi-buy 10-20k' },
            { value: '$$$$', text: 'Full-buy 20k+' },
          ].map(s => {
            const unique_vals = [...new Set((attack_speed || []).map(e => e.scenario))]
            return {
              ...s,
              disabled: !unique_vals.includes(s.value),
            }
          })

          const plants = [...new Set((attack_speed || []).map(e => e.plant_site).sort((a, b) => (a > b ? 1 : -1)))]

          commit('UPDATE_PLANT_PERFORMANCE', attack_speed || [])
          commit('UPDATE_PLANT_PERFORMANCE_FILTERS', { plants, scenarios })

          // WIN CONDITIONS
          const win_conditions_mapped = win_conditions.map(res => {
            const bombsite = res.plant_site
            const condition = res.round_result

            // Win N = wins
            // Win % = wins / picks * 100
            // Losses N = picks - wins
            // Losses % = (picks - wins) / picks * 100

            const atk_wins = res.attacking.wins
            const atk_wins_percentage =
              atk_wins / res.attacking.picks ? Math.round((atk_wins / res.attacking.picks) * 100) : 0

            const atk_loses = res.attacking.picks - atk_wins
            const atk_loses_percentage =
              atk_loses / res.attacking.picks ? Math.round((atk_loses / res.attacking.picks) * 100) : 0

            const def_wins = res.defending.wins
            const def_wins_percentage =
              def_wins / res.defending.picks ? Math.round((def_wins / res.defending.picks) * 100) : 0

            const def_loses = res.defending.picks - def_wins
            const def_loses_percentage =
              def_loses / res.defending.picks ? Math.round((def_loses / res.defending.picks) * 100) : 0

            return {
              bombsite,
              condition,
              atk_wins,
              atk_wins_percentage,
              atk_loses,
              atk_loses_percentage,
              def_wins,
              def_wins_percentage,
              def_loses,
              def_loses_percentage,
            }
          })

          commit('UPDATE_WIN_CONDITIONS', win_conditions_mapped)

          // WEAPON STATS
          const weapon_stats_mapped = weapons.map(weapon => {
            const weapon_name = getWeaponById(weapon.id)?.name
            const weapon_image_url = `/images/weapons-white/${weapon_name?.toLowerCase()}.png`
            const picks = weapon.picks
            const kills = weapon.kills

            return {
              weapon_name,
              weapon_image_url,
              picks,
              kills,
              rounds_played: total_rounds,
              pick_percentage: `${Math.round((picks / total_rounds) * 100)}%`,
            }
          })

          commit('UPDATE_WEAPON_STATS', weapon_stats_mapped)

          const performance_atk = plants
            .filter(plant => !!plant)
            .map(plant => {
              const [plants_atk] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.attacking.plants)

              const [post_plants_won] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.attacking.wins)

              const [post_plants_lost] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.attacking.plants - post_plant.attacking.wins)

              return {
                bombsite: plant || 'No Plant',
                plants_atk,
                rounds: false,
                win: false,
                loss: false,
                post_plants_won,
                post_plants_lost,
              }
            })

          const performance_def = plants
            .filter(plant => !!plant)
            .map(plant => {
              const [plants_def] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.defending.plants)

              const [retakes_win] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.defending.wins)

              const [retakes_loss] = post_plants
                .filter(post_plant => post_plant.plant_site === plant)
                .map(post_plant => post_plant.defending.plants - post_plant.defending.wins)

              const retakes_win_percentage = plants_def ? (retakes_win / plants_def) * 100 : plants_def
              const retakes_loss_percentage = plants_def ? (retakes_loss / plants_def) * 100 : plants_def

              return {
                bombsite: plant || 'No Plant',
                plants_def,
                rounds: false,
                win: false,
                loss: false,
                retakes: false,
                retakes_win,
                retakes_win_percentage,
                retakes_loss,
                retakes_loss_percentage,
              }
            })

          commit('UPDATE_PERFORMANCE_ATK', performance_atk)
          commit('UPDATE_PERFORMANCE_DEF', performance_def)
        })
        .catch(error => {
          console.error(
            `Failure team/get_maps_stats({ ${team_id}, ${map_id}, ${agent_comps}, ${begin_time}, ${end_time} })`,
            error
          )
          commit('UPDATE_LOADING_STATS', false)
          Sentry.captureException(error)
          commit('UPDATE_SERVER_ERROR', error.response?.data.detail)
        })
    },

    // eslint-disable-next-line no-unused-vars
    search_matches({ commit, dispatch }, params) {
      console.info(`%c team/search_matches(${Object.values(params)})`, 'color:#38bfb2')

      return findMatches(params).catch(server_error => {
        console.error(`Failure team/search_matches(${Object.values(params)})`, server_error)
        commit('UPDATE_SERVER_ERROR', server_error)
        Sentry.captureException(server_error)
        return { matches: [], total: 0 }
      })
    },
  },
}
