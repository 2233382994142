import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    lastCompositionsPerMap: {},
    lastTeam1Name: 'Own',
    lastTeam2Name: 'Opponent',
  },

  getters: {
    getLastCompositionsForMap: state => mapId => state.lastCompositionsPerMap[mapId],
    lastTeam1Name: state => state.lastTeam1Name,
    lastTeam2Name: state => state.lastTeam2Name,
  },

  mutations: {
    setLastComposition: (state, { mapId, composition }) =>
      Vue.set(state.lastCompositionsPerMap, mapId, Object.freeze(composition?.map(item => Object.freeze({ ...item })))),
    setLastTeam1Name: (state, value) => (state.lastTeam1Name = value),
    setLastTeam2Name: (state, value) => (state.lastTeam2Name = value),
  },
}
