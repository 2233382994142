export const isTradeKill = (kill, otherKills) =>
  otherKills.some(
    otherKill =>
      kill.round_time_millis >= otherKill.round_time_millis &&
      kill.round_time_millis - otherKill.round_time_millis < 3000 &&
      kill.victim_puuid === otherKill.killer_puuid
  )

export const isClutch = (teamPlayers, kills) => {
  return kills.filter(kill => teamPlayers.some(player => player.puuid === kill.victim_puuid))?.length > 3
}

export const getTradedPuuid = (kill, otherKills) => {
  let traded = ''
  otherKills.forEach(function (otherKill) {
    if (
      kill.round_time_millis >= otherKill.round_time_millis &&
      kill.round_time_millis - otherKill.round_time_millis < 3000 &&
      kill.victim_puuid === otherKill.killer_puuid
    ) {
      traded = otherKill.victim_puuid
    }
  })
  return traded
}
