class BaseError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
  }
}

export class NotAuthorizedError extends BaseError {
  static name = 'NotAuthorizedError'
}

export class InsufficientQuotaError extends BaseError {
  static name = 'InsufficientQuotaError'
}
