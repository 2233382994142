import * as Sentry from '@sentry/vue'
import axios from 'axios'

import InsufficientQuotaError from '@/api/InsufficientQuotaError'
import { clearSessionKey, redirectToSSO } from '@/utils/sso'

import storage from './store/storage.js'

function request_interceptor(config) {
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    'X-Augment-Session-Key': storage.getItem('SESSION_KEY'),
  }
  return config
}

function request_error_interceptor(error) {
  return Promise.reject(error)
}

function permissionsInterceptor(error) {
  if (error?.response?.status === 402) {
    throw new InsufficientQuotaError(error?.response?.data?.detail)
  }
  if (error?.response?.status === 401) {
    clearSessionKey()
    redirectToSSO()
  }
  return Promise.reject(error)
}

const valueToParam = (value, key) => {
  switch (typeof value) {
    case 'boolean':
      return value ? [[key, 'true']] : []
    case 'number':
    case 'string':
    case 'bigint':
      return [[key, String(value)]]
    case 'object':
      return Array.isArray(value) ? value.flatMap(sub => valueToParam(sub, key)) : objMapToParams(value, key)
    case 'undefined':
      return []
    default:
      throw new Error(`Cannot serialize query param ${key} of type ${typeof value} and value ${value}`)
  }
}

const objMapToParams = (params, prefix = '') =>
  params
    ? Object.entries(params).flatMap(([selfKey, value]) => {
        const key = prefix ? `${prefix}.${selfKey}` : selfKey
        return valueToParam(value, key)
      })
    : []

const axios_instance = axios.create({
  baseURL: storage.getItem('CURRENT_BACKEND') || process.env.VUE_APP_API_URL,
  paramsSerializer: params => new URLSearchParams(objMapToParams(params)).toString(),
  timeout: 0,
  withCredentials: true,
})
Sentry.setTag('backend', axios_instance.defaults.baseURL)

axios_instance.interceptors.request.use(request_interceptor, request_error_interceptor)
axios_instance.interceptors.response.use(null, permissionsInterceptor)

axios_instance.CancelToken = axios.CancelToken
axios_instance.isCancel = axios.isCancel
axios_instance.extractErrorMessage = e => {
  let msg = e.message
  if (e.response) {
    msg += ': '
    msg += JSON.stringify(e.response.data)
  }
  return msg
}

export const getBackend = () => axios_instance.defaults.baseURL
export const setBackend = baseURL => {
  axios_instance.defaults.baseURL = baseURL
  storage.setItem('CURRENT_BACKEND', baseURL)
  Sentry.setTag('backend', baseURL)
}
export const getExtraBackends = () =>
  JSON.parse(storage.getItem('BACKEND_EXTRA') || JSON.stringify({ export: process.env.VUE_APP_EXPORT_URL }))
// eslint-disable-next-line no-unused-vars
export const setExtraBackends = ({ label, backend, ..._extraBackends } = {}) => {
  const extraBackends = { ..._extraBackends }
  storage.setItem('BACKEND_EXTRA', JSON.stringify(extraBackends))
  Sentry.setTags(extraBackends)
}

export default axios_instance
