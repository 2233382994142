/**
 * Rotate 90 degrees
 * @param {API_LOCATION} l
 * @param {number} deg
 * @return {API_LOCATION}
 */
export default function rotate(l, deg) {
  deg %= 360
  while (deg < 0) deg += 360
  switch (deg) {
    case 0:
      return l
    case 90:
      // noinspection JSSuspiciousNameCombination
      return { x: l.y, y: 1 - l.x }
    case 180:
      return { x: 1 - l.x, y: 1 - l.y }
    case 270:
      // noinspection JSSuspiciousNameCombination
      return { x: 1 - l.y, y: l.x }
    default:
      throw new Error(`Unhandled rotation to ${deg}`)
  }
}
