// import axios from '@/axios'
// import theaxios from 'axios'
// import { sort_by_score } from '@/helper.js'

export default {
  namespaced: true,

  state: {
    selected: null,
    loading: false,
    server_error: null,
  },

  mutations: {
    UPDATE_ERROR: (state, error) => (state.server_error = error),
    UPDATE_LOADING: (state, data) => (state.loading = data),
    UPDATE_SELECTED: (state, data) => (state.selected = data),
  },

  getters: {
    server_error: state => state.server_error,
    loading: state => state.loading,
    selected: state => state.selected,
  },

  actions: {
    update_selected({ commit, state }, { match_id, rounds }) {
      console.info(`%c rounds/update_selected(${match_id}, ${rounds})`, 'color:#38bfb2')

      const entries = {
        ...state.selected,
      }

      if (rounds.length) {
        entries[match_id] = rounds
      } else {
        delete entries[match_id]
      }

      commit('UPDATE_SELECTED', entries)
    },
  },
}
