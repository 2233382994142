import mixpanel from 'mixpanel-browser'

import { getBackend } from '@/axios'
import store from '@/store/index.js'

const increment = (...args) => {
  if (process.env.VUE_APP_MIXPANEL_DEBUG === 'true') {
    console.info(`%cMIXPANEL %cincrement`, 'color: #4f44e0; font-size: 16px', 'color: #aaa', ...args)
  }

  if (!process.env.VUE_APP_MIXPANEL_TOKEN) {
    return
  }

  mixpanel.people.increment(...args)
}

const track_event = (event, params) => {
  const username = store.getters['auth/username']
  event = event.replace('<username>', username || 'user')

  if (process.env.VUE_APP_MIXPANEL_DEBUG === 'true') {
    console.info(`%cMIXPANEL %c${event}`, 'color: #4f44e0; font-size: 16px', 'color: #aaa', params)
  }

  if (!process.env.VUE_APP_MIXPANEL_TOKEN) {
    return
  }

  mixpanel.track(event, {
    ...params,
    username,
    user_id: store.getters['auth/user_id'],
    email: store.getters['auth/email'],
  })
}

export default {
  /**
   * @param {API_USER_DATA} userData
   */
  identify(userData) {
    if (!process.env.VUE_APP_MIXPANEL_TOKEN) {
      return
    }
    mixpanel.identify(userData.id)
    mixpanel.people.set({
      backend: getBackend(),
      access_tier: userData.access_tier,
      date_joined: new Date(userData.date_joined),
      date_updated: new Date(userData.date_updated),
      $name: userData.username,
      $email: userData.email,
      email_verified: userData.email_verified,
      is_active: userData.is_active,
    })
  },

  register(...args) {
    if (!process.env.VUE_APP_MIXPANEL_TOKEN) {
      return
    }
    mixpanel.register(...args)
  },

  reset() {
    if (!process.env.VUE_APP_MIXPANEL_TOKEN) {
      return
    }
    mixpanel.reset()
  },

  // Track Search
  track_search: (params, button) => {
    track_event(`[search] <username> ${button} matches`, params)
  },

  // Track Srarch Team/Player
  track_search_profile: (params, team, player, map, button) => {
    track_event(
      `[search] <username> ${button} matches for ${
        player ? `player "${player}" (${team})` : `team "${team}"`
      } on map ${map}`,
      { ...params, team, player, map }
    )
  },

  // Track Search Results Dropdown
  track_search_results_dropdown: match_id => {
    track_event(`[search] <username> open dropdown for match ${match_id}`, { match_id })
  },

  // Track Search Round Selection
  track_search_round_selection: (params, match_id) => {
    track_event(`[search] <username> select rounds for match ${match_id}`, { ...params, match_id })
  },

  // Track Search Pagination
  track_search_pagination: (params, page) => {
    track_event(`[search] <username> change page to ${page}`, { ...params, page })
  },

  // Track Search Count
  track_search_count: (params, count) => {
    track_event(`[search] <username> change search count to ${count}`, { ...params, count })
  },

  // Track Match navigation
  track_match_navigation: (match_id, round, button) => {
    track_event(
      `[match] <username> goes to match ${match_id} page via "${button}" button${
        round !== undefined ? ' for round ' + round : ''
      }`,
      { match_id, round, button }
    )
  },

  // Track Match Tabs
  track_match_tab: (match_id, tab) => {
    track_event(`[match] <username> navigate match ${match_id} to "${tab}" tab`, { match_id, tab })
  },

  // Track Match Rounds
  track_match_rounds: (match_id, round) => {
    track_event(`[match] <username> navigate match ${match_id} page Round Stats for round ${round}`, {
      match_id,
      round,
    })
  },

  // When user select match(es)
  track_match_select_all_rounds: match_id => {
    track_event(`[search] <username> SELECT ALL ROUNDS for match ${match_id}`, { match_id })
  },

  // When user deselect match(es)
  track_match_deselect_all_rounds: match_id => {
    track_event(`[search] <username> DESELECT ALL ROUNDS for match ${match_id}`, { match_id })
  },

  track_2d_map_drawing_add: ({ mapMode }) => {
    track_event(`[map] <username> added drawing on ${mapMode}`, { mapMode })
  },

  track_2d_map_drawing_clear: ({ mapMode }) => {
    track_event(`[map] <username> cleared drawings on ${mapMode}`, { mapMode })
  },

  track_2d_map_drawing_undo: ({ mapMode }) => {
    track_event(`[map] <username> undo last drawing on ${mapMode}`, { mapMode })
  },

  // When user goes/switch to 2D Map views
  track_2d_map_navigation: (params, button, map) => {
    const location = `2DMap/${button}`

    track_event(`[map] <username> navigate "${location}" for ${map}`, { ...params, location, map })
  },

  // Change 2D Map Spike
  track_2d_map_spike: spike_mode => {
    track_event(`[map] <username> switch SPIKE MODE to "${spike_mode}"`, { spike_mode })
  },

  // Change 2D Map Type
  track_2d_map_type: map_type => {
    track_event(`[map] <username> switch MAP TYPE to "${map_type}"`, { map_type })
  },

  // Change 2D Map Options
  track_2d_map_options: map_options => {
    track_event(`[map] <username> switch MAP OPTIONS to "${map_options.join(', ')}"`, { map_options })
  },

  // Change Filter
  track_2d_map_filter: (filter, value) => {
    track_event(`[map] <username> set filter ${filter} to "${value}"`)
  },

  // Change 2D Map Selection
  track_2d_map_selection: selection => {
    track_event(`[map] <username> change selection to "${JSON.stringify(selection)}"`, { selection })
  },

  // Change 2D Map Playback speed
  track_2d_map_playback_rate: playbackSpeed => {
    track_event(`[map] <username> change playback speed to x${playbackSpeed}`, { playbackSpeed })
  },

  // Change 2D Map VOD Player
  track_2d_map_vod_player: vodPlayer => {
    track_event(`[map] <username> change vod player to ${vodPlayer}`, { vodPlayer })
  },

  // Change 2D Map Playing state
  track_2d_map_playing: playing => {
    track_event(`[map] <username> ${playing ? 'started' : 'stopped'} playback/replay`, { playing })
  },

  // Force render of many object on 2D Map
  track_2d_map_force_render: (map_mode, map_type, count) => {
    track_event(`[map] <username> forced render of ${count} object on ${map_mode} ${map_type}`, {
      map_mode,
      map_type,
      count,
    })
  },

  // When user adds VOD url
  track_vod_upload: (params, match_id) => {
    track_event(`[match] <username> uploaded VOD for match ${match_id}`, { ...params, match_id })
  },

  // Navigate Team map
  track_team_map: (team, map) => {
    track_event(`[team] <username> changed map on team "${team}" page to "${map}"`)
  },

  // Navigate Player map
  track_player_map: (player, team, map) => {
    track_event(`[player] <username> changed map on player "${player}" (${team}) page to "${map}"`)
  },

  // Navigate Team Sub page
  track_team_sub_page: (team, sub_page, map) => {
    track_event(`[team] <username> navigate team "${team}" page to "${sub_page}" (${map})`)
  },

  // Navigate Player Sub page
  track_player_sub_page: (player, team, sub_page, map) => {
    track_event(`[player] <username> navigate player "${player}" (${team}) page to "${sub_page}" (${map})`)
  },

  // Plant Performance filters
  track_plant_performance_filters: params => {
    track_event('[chart] <username> changed Plant Performance filters', params)
  },

  // Track all user navigation
  track_router: (route_name, full_path) => {
    track_event(`[router] <username> navigate to route "${route_name}"`, { full_path, route_name })
  },

  // All auth events
  track_auth: (action, params) => {
    track_event(`[auth] <username> ${action}`, params)
  },

  track_report_create: report => {
    track_event(`[report] <username> created report "${report.name}" for team "${report.team.name}"`, report)
  },

  track_report_load: report => {
    track_event(`[report] <username> loaded report "${report.name}" for team "${report.team.name}"`, report)
  },

  track_tab_activated: title => {
    track_event(`[tab] ${title} activated`)
  },

  track_create_bookmark: ({ type, id }) => {
    increment({
      [`${type}_bookmarks`]: 1,
      [`${type}_bookmarks:create`]: 1,
    })
    track_event(`bookmark ${type}`, {
      category: 'bookmark',
      subcategory: type,
      event: 'create',
      label: id,
    })
  },

  track_delete_bookmark: ({ type, id }) => {
    increment({
      [`${type}_bookmarks`]: -1,
      [`${type}_bookmarks:delete`]: 1,
    })
    track_event(`unbookmark ${type}`, {
      category: 'bookmark',
      subcategory: type,
      event: 'delete',
      label: id,
    })
  },

  track_list_bookmark: ({ type, count, index }) => {
    increment({
      [`${type}_bookmarks:list`]: 1,
    })
    track_event(`list ${type} bookmark`, {
      category: 'bookmark',
      subcategory: type,
      event: 'list',
      count,
      index,
    })
  },

  track_deselect({ type, id, selected }, ctx) {
    increment({
      [`${type}_selection`]: -1,
      [`${type}_selection:deselect`]: 1,
    })
    track_event(`deselect ${type}`, {
      ...ctx,
      category: 'selection',
      subcategory: type,
      event: 'deselect',
      label: id,
      list_length: selected.length,
    })
  },
  track_select({ type, id, selected }, ctx) {
    increment({
      [`${type}_selection`]: 1,
      [`${type}_selection:select`]: 1,
    })
    track_event(`select ${type}`, {
      ...ctx,
      category: 'selection',
      subcategory: type,
      event: 'select',
      label: id,
      list_length: selected.length,
    })
  },

  track_link({ type, href }, ctx) {
    track_event(`click link ${type}`, {
      ...ctx,
      category: 'link',
      subcategory: type,
      event: 'click',
      label: href,
    })
  },

  track_scrim_round: (scrim, round) => {
    track_event(`[scrim] <username> selects round ${round} for scrim ${scrim.id} (${scrim.title})`, {
      scrim_id: scrim.id,
      scrim_title: scrim.title,
      round,
    })
  },

  // Change Scrim 2D Map Playback speed
  track_scrim_2d_map_playback_rate: playbackSpeed => {
    track_event(`[scrim] change playback speed to x${playbackSpeed}`, { playbackSpeed })
  },

  // Change Scrim 2D Map Playing state
  track_scrim_2d_map_playing: playing => {
    track_event(`[scrim] ${playing ? 'started' : 'stopped'} playback/replay`, { playing })
  },

  // Change Scrim 2D Map Selection
  track_scrim_2d_map_selection: selection => {
    track_event(`[scrim] change selection to "${JSON.stringify(selection)}"`, { selection })
  },

  // Change Scrim 2D Map VOD Player
  track_scrim_2d_map_vod_player: vodPlayer => {
    track_event(`[scrim] change vod player to ${vodPlayer}`, { vodPlayer })
  },

  track_scrim_load_data: params => {
    track_event(`[scrim] load data`, { params })
  },

  track_scrim_upload: params => {
    track_event(`[scrim] uploaded VOD`, { params })
  },

  track_scrim_load_failure: params => {
    track_event(`[scrim] load failure`, { params })
  },

  track_scrim_edit: scrim => {
    track_event(`[scrim] edit scrim ${scrim.id}`, {
      scrim_id: scrim.id,
      scrim_title: scrim.title,
    })
  },

  track_note_create: note => {
    track_event(
      `[note] <username> created note "${note.content}" for ${note.isScrim ? 'scrim' : 'match'} "${note.target_id}"`
    )
  },

  /**
   * @param {'match'} type
   * @param {string[]} list
   */
  track_export: (type, list, extra) => {
    track_event(`export ${type}`, {
      category: 'export',
      subcategory: type,
      event: 'export',
      list_length: list.length,
      ...extra,
    })
  },

  track_sw_event: (eventName, event) => {
    track_event(`sw.${eventName}`, {
      isExternal: event.isExternal,
      data: event.data ? JSON.parse(JSON.stringify(event.data)) : event.data,
      isUpdate: event.isUpdate,
      wasWaitingBeforeRegister: event.wasWaitingBeforeRegister,
      swState: event.sw?.state,
      swUrl: event.sw?.scriptURL,
    })
  },

  track_sw_force_activate: () => {
    track_event(`sw.force_activate`)
  },
}
