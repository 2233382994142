export default {
  namespaced: true,

  state: {
    data: null,
  },

  mutations: {
    SET_VALORANT_API_AGENTS(state, data) {
      state.data = data
    },
  },

  actions: {
    setAgents({ commit, state }, agents) {
      if (!state.data && agents) commit('SET_VALORANT_API_AGENTS', agents)
    },
  },

  getters: {
    agents: state => state.data,
  },
}
