import * as Sentry from '@sentry/vue'

const CHECK_STORAGE_KEY = '____storage_check_quota'
const MIN_STORAGE_CAPACITY = 2048

class InternalStorage {
  constructor() {
    this._storage = {}
  }
  get length() {
    return Object.keys(this._storage).length
  }
  clear() {
    this._storage = {}
  }
  getItem(key) {
    return this._storage[key] ?? null
  }
  key(index) {
    return Object.keys(this._storage)[index] ?? null
  }
  removeItem(key) {
    delete this._storage[key]
  }
  setItem(key, value) {
    this._storage[key] = String(value)
  }
}

class MapStorage {
  constructor() {
    this._storage = new Map()
  }
  get length() {
    return this._storage.size
  }
  clear() {
    this._storage.clear()
  }
  getItem(key) {
    return this._storage.get(key) ?? null
  }
  key(index) {
    return [...this._storage.keys()][index] ?? null
  }
  removeItem(key) {
    this._storage.delete(key)
  }
  setItem(key, value) {
    this._storage.set(key, String(value))
  }
}

function copyStorage(from, to) {
  try {
    const length = from.length
    for (let index = 0; index < length; index++) {
      const key = from.key(index)
      to.setItem(key, from.getItem(key))
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

function createFailsafeStorage(storage) {
  let fallback = null
  return new Proxy(storage, {
    get(target, prop, receiver) {
      if (fallback) {
        target = fallback
      }
      const original = target[prop]

      // [name: string]: any;
      if (!original) {
        try {
          return target.getItem(prop) ?? null
        } catch (e) {
          Sentry.captureException(e)
          return null
        }
      }

      if (original instanceof Function) {
        return function (...args) {
          try {
            return original.apply(this === receiver ? target : this, args)
          } catch (e) {
            if (e.name === 'QuotaExceededError' && !fallback) {
              const storage = target
              // happens when using localStorage
              // move to map storage
              try {
                console.log('falling back to map storage')
                target = fallback = new MapStorage()
              } catch (e) {
                Sentry.captureException(e)
                target = fallback = new InternalStorage()
              }
              copyStorage(storage, target)
              try {
                return target[prop].apply(this === receiver ? target : this, args)
              } catch (e) {
                Sentry.captureException(e)
              }
            } else {
              Sentry.captureException(e)
              return null
            }
          }
        }
      }
    },
  })
}

function checkStorageHasCapacity(storage, capacity) {
  try {
    storage.setItem(CHECK_STORAGE_KEY, 'x'.repeat(capacity))
    storage.removeItem(CHECK_STORAGE_KEY)
    return true
  } catch (e) {
    return false
  }
}

function createStorage() {
  if (checkStorageHasCapacity(window.localStorage, MIN_STORAGE_CAPACITY)) {
    console.log('using local storage')
    return createFailsafeStorage(window.localStorage)
  } else if (checkStorageHasCapacity(window.sessionStorage, MIN_STORAGE_CAPACITY)) {
    console.log('using session storage')
    const storage = createFailsafeStorage(window.sessionStorage)
    copyStorage(window.localStorage, storage)
    return storage
  }
  try {
    console.log('using map storage')
    const storage = createFailsafeStorage(new MapStorage())
    copyStorage(window.localStorage, storage)
    copyStorage(window.sessionStorage, storage)
    return storage
  } catch (e) {
    Sentry.captureException(e)
    const storage = createFailsafeStorage(new InternalStorage())
    copyStorage(window.localStorage, storage)
    copyStorage(window.sessionStorage, storage)
    return storage
  }
}

const storage = createStorage()

export default storage
