import * as Sentry from '@sentry/vue'

import { getPlayerInfo, getPlayerStats } from '../../api/players.js'

export default {
  namespaced: true,

  state: {
    loading_info: false,
    loading_stats: false,
    name: null,
    realname: null,
    team_id: null,
    team_name: null,
    team_region: null,
    team_image: null,
    server_error: null,
    weapon_stats: [],
    has_map_data: false,

    general_stats: null,
    agents_stats: null,
    aim_stats: null,
    per_role_stats: null,
    clutches: null,

    data_different_from_team: null, // REMOVE
  },

  mutations: {
    UPDATE_LOADING_INFO: (state, data) => (state.loading_info = data),
    UPDATE_LOADING_STATS: (state, data) => (state.loading_stats = data),
    UPDATE_MEMBERS: (state, data) => (state.members = data),
    UPDATE_NAME: (state, data) => (state.name = data),
    UPDATE_TEAM_ID: (state, data) => (state.team_id = data),
    UPDATE_TEAM_NAME: (state, data) => (state.team_name = data),
    UPDATE_TEAM_IMAGE: (state, data) => (state.team_image = data),
    // UPDATE_REGION: (state, data) => state.region = data,
    UPDATE_SERVER_ERROR: (state, error) => (state.server_error = error),
    UPDATE_WEAPON_STATS: (state, data) => (state.weapon_stats = data),
    UPDATE_HAS_MAP_DATA: (state, data) => (state.has_map_data = data),

    UPDATE_GENERAL_STATS: (state, data) => (state.general_stats = data),
    UPDATE_AGENTS_STATS: (state, data) => (state.agents_stats = data),
    UPDATE_AIM_STATS: (state, data) => (state.aim_stats = data),
    UPDATE_PER_ROLE_STATS: (state, data) => (state.per_role_stats = data),
    UPDATE_CLUTCHES_STATS: (state, data) => (state.clutches = data),

    UPDATE_DATA_DIFFERENT_FROM_TEAM: (state, data) => (state.data_different_from_team = data), // REMOVE
  },

  getters: {
    image: state => state.image,
    loading_info: state => state.loading_info,
    loading_stats: state => state.loading_stats,
    name: state => state.name,
    team_id: state => state.team_id,
    team_name: state => state.team_name,
    team_image: state => state.team_image,
    // region: state => state.region,
    server_error: state => state.server_error,
    weapon_stats: state => state.weapon_stats,
    has_map_data: state => state.has_map_data,

    general_stats: state => state.general_stats,
    agents_stats: state => state.agents_stats,
    aim_stats: state => state.aim_stats,
    per_role_stats: state => state.per_role_stats,
    clutches: state => state.clutches,

    data_different_from_team: state => state.data_different_from_team, // REMOVE
  },

  actions: {
    get_player_info({ commit }, id) {
      console.info(`%c player/get_player_info({ ${id} })`, 'color:#38bfb2')

      commit('UPDATE_LOADING_INFO', true)
      commit('UPDATE_SERVER_ERROR', null)

      return getPlayerInfo(id)
        .then(data => {
          // commit('UPDATE_IMAGE', response.data.image)
          commit('UPDATE_LOADING_INFO', false)
          commit('UPDATE_NAME', data.name)
          commit('UPDATE_TEAM_ID', data.team.id)
          commit('UPDATE_TEAM_NAME', data.team.name)
          commit('UPDATE_TEAM_IMAGE', data.team.image)
          // commit('UPDATE_REGION', response.data.region)
          return data
        })
        .catch(error => {
          console.error(`Failure player/get_player_info({ ${id} })`, error)
          commit('UPDATE_SERVER_ERROR', error.response.data.detail)
          Sentry.captureException(error)
        })
    },

    get_maps_stats(
      { commit, rootGetters: { 'static/agentsList': agentsList, 'static/getWeaponById': getWeaponById } },
      { player_id, map_id }
    ) {
      if (!map_id) {
        return
      }

      console.info(`%c player/get_maps_stats({ ${player_id}, ${map_id} })`, 'color:#38bfb2')

      commit('UPDATE_LOADING_STATS', true)
      commit('UPDATE_SERVER_ERROR', null)

      return getPlayerStats(player_id, {
        map_ids: [map_id],
      })
        .then(data => {
          commit('UPDATE_LOADING_STATS', false)

          const {
            aim_stats,
            assists,
            avr_round_damage,
            avr_round_score,
            clutches,
            deaths,
            first_kills,
            kills,
            played_agents,
            total_games,
            // ---
            total_rounds,
            weapons,
          } = data

          commit('UPDATE_HAS_MAP_DATA', !!total_rounds)

          commit('UPDATE_GENERAL_STATS', [
            {
              assists,
              deaths,
              kills,
              total_games,
            },
          ])

          commit(
            'UPDATE_AGENTS_STATS',
            played_agents.map(agent => {
              const [a] = agentsList.filter(sa => sa.id === agent.id)

              return {
                agent: {
                  name: a.name,
                  icon: a.display_icon_url,
                },
                wins: agent.wins,
                wins_percentage: (agent.wins / agent.picks) * 100,
                loses: agent.picks - agent.wins,
                loses_percentage: ((agent.picks - agent.wins) / agent.picks) * 100,
              }
            })
          )

          commit(
            'UPDATE_AIM_STATS',
            aim_stats.map((stat, _, all_stats) => {
              const total = all_stats.map(s => s.count).reduce((acc, c) => acc + c, 0)
              const stat_percentage = Math.round((stat.count / total) * 100)

              return {
                ...stat,
                stat_percentage,
              }
            })
          )

          commit(
            'UPDATE_PER_ROLE_STATS',
            avr_round_damage.map(adr => {
              const [acs] = avr_round_score.filter(data => data.role === adr.role).map(data => data.value)

              const [fk] = first_kills.filter(data => data.role === adr.role).map(data => data.value)

              return {
                role: adr.role,
                adr: Math.round((adr.value + Number.EPSILON) * 100) / 100,
                acs: Math.round((acs + Number.EPSILON) * 100) / 100,
                fk,
              }
            })
          )

          commit(
            'UPDATE_CLUTCHES_STATS',
            clutches.map(clutch => {
              const { type, wins, rounds } = clutch
              const loses = rounds - wins
              const wins_percentage = Math.round((wins / rounds) * 100)
              const loses_percentage = Math.round((loses / rounds) * 100)

              return {
                type,
                wins,
                wins_percentage,
                loses,
                loses_percentage,
                rounds,
              }
            })
          )

          commit('UPDATE_DATA_DIFFERENT_FROM_TEAM', {
            aim_stats,
            assists,
            avr_round_damage,
            avr_round_score,
            clutches,
            deaths,
            first_kills,
            kills,
            played_agents,
            total_games,
          })

          // if (!total_rounds) {
          //   return
          // }

          // // PLANT PERFORMANCE
          // // console.table(attack_speed);

          // // filter only uniq values
          // const plant_performance_filters = {
          //   plants: [
          //     ...new Set((attack_speed || [])
          //       .map(e => e.plant_site)
          //       .sort((a, b) => a > b ? 1 : -1))
          //   ],
          //   scenarios: [
          //     ...new Set((attack_speed || [])
          //       .map(e => e.scenario)
          //       .sort((a, b) => a < b ? 1 : -1))
          //   ],
          // }

          // commit('UPDATE_PLANT_PERFORMANCE', attack_speed || [])
          // commit('UPDATE_PLANT_PERFORMANCE_FILTERS', plant_performance_filters)

          // // WIN CONDITIONS
          // const win_conditions_mapped = win_conditions.map(res => {
          //   const bombsite = res.plant_site
          //   const condition = res.round_result

          //   // Win N = wins
          //   // Win % = wins / picks * 100
          //   // Losses N = picks - wins
          //   // Losses % = (picks - wins) / picks * 100

          //   const atk_wins = res.attacking.wins
          //   const atk_wins_percentage = atk_wins / res.attacking.picks
          //     ? Math.round((atk_wins / res.attacking.picks) * 100)
          //     : 0

          //   const atk_loses = res.attacking.picks - atk_wins
          //   const atk_loses_percentage = atk_loses / atk_loses
          //     ? Math.round((atk_loses / atk_loses) * 100)
          //     : 0

          //   const def_wins = res.defending.wins
          //   const def_wins_percentage = def_wins / res.defending.picks
          //     ? Math.round((def_wins / res.defending.picks) * 100)
          //     : 0

          //   const def_loses = res.defending.picks - def_wins
          //   const def_loses_percentage = def_loses / def_loses
          //     ? Math.round((def_loses / def_loses) * 100)
          //     : 0

          //   return {
          //     bombsite,
          //     condition,
          //     atk_wins,
          //     atk_wins_percentage,
          //     atk_loses,
          //     atk_loses_percentage,
          //     def_wins,
          //     def_wins_percentage,
          //     def_loses,
          //     def_loses_percentage,
          //   }
          // })

          // commit('UPDATE_WIN_CONDITIONS', win_conditions_mapped)

          // WEAPON STATS
          const weapon_stats_mapped = weapons.map(weapon => {
            const weapon_name = getWeaponById(weapon.id)?.name
            const weapon_image_url = `/images/weapons-white/${weapon_name.toLowerCase()}.png`
            const picks = weapon.picks
            const kills = weapon.kills

            return {
              weapon_name,
              weapon_image_url,
              picks,
              kills,
              rounds_played: total_rounds,
              pick_percentage: `${Math.round((picks / total_rounds) * 100)}%`,
            }
          })

          commit('UPDATE_WEAPON_STATS', weapon_stats_mapped)
        })
        .catch(error => {
          console.error(`Failure player/get_maps_stats({ ${player_id}, ${map_id} })`, error)
          commit('UPDATE_LOADING_STATS', false)
          commit('UPDATE_SERVER_ERROR', error.response.data.detail)
          Sentry.captureException(error)
        })
    },
  },
}
