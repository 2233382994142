<template>
  <ErrorAlert>
    It looks like you're running an old version of the app. Please wait for the new version to be installed or reload.
  </ErrorAlert>
</template>

<script>
import ErrorAlert from '@/components/generic/ErrorAlert.vue'
export default {
  name: 'ErrorFallback',
  components: { ErrorAlert },
}
</script>

<style scoped></style>
