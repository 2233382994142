import { polygonContains } from 'd3-polygon'

const HAVEN_ZONES = parseZone(`#Zone 10
x_{1} = 0.444,0.549,0.549,0.435
y_{1} = 0.586,0.586,0.674,0.674
#Zone 12
x_{2} = 0.274,0.274,0.313,0.313,0.42,0.42,0.508,0.508,0.42,0.42,0.311,0.311,0.274
y_{2} = 0.688,0.688,0.688,0.723,0.723,0.746,0.746,0.902,0.902,0.922,0.922,0.77,0.77
#Zone 11
x_{3} = 0.256,0.256,0.332,0.332,0.352,0.352,0.386,0.386,0.398,0.398,0.434,0.434,0.314,0.314,0.273,0.273,0.256
y_{3} = 0.633,0.633,0.633,0.586,0.586,0.576,0.576,0.604,0.604,0.586,0.586,0.693,0.693,0.688,0.688,0.666,0.666
#Zone 13
x_{4} = 0.509,0.509,0.572,0.572,0.631,0.631,0.664,0.664,0.75,0.75,0.639,0.639,0.509
y_{4} = 0.82,0.82,0.82,0.781,0.781,0.82,0.82,0.768,0.768,0.893,0.893,0.863,0.863
#Zone 14
x_{5} = 0.664,0.664,0.746,0.746,0.803,0.803,0.762,0.762,0.664
y_{5} = 0.691,0.691,0.691,0.637,0.637,0.787,0.787,0.767,0.767
#Zone 15
x_{6} = 0.746,0.746,0.803,0.803,0.717,0.717,0.746
y_{6} = 0.477,0.477,0.477,0.636,0.636,0.535,0.535
#Zone 8
x_{7} = 0.678,0.678,0.795,0.795,0.721,0.721,0.678
y_{7} = 0.34,0.34,0.34,0.475,0.475,0.424,0.424
#Zone 5
x_{8} = 0.273,0.273,0.318,0.318,0.342,0.469,0.469,0.342,0.318,0.318,0.273
y_{8} = 0.467,0.467,0.467,0.447,0.422,0.422,0.574,0.574,0.551,0.531,0.531
#Zone 9
x_{9} = 0.582,0.582,0.719,0.719,0.689,0.689,0.652,0.652,0.664,0.664,0.715,0.715,0.551,0.551,0.582,0.582,0.523,0.523,0.477,0.477,0.582
y_{9} = 0.43,0.43,0.43,0.465,0.465,0.553,0.553,0.582,0.582,0.566,0.566,0.674,0.674,0.586,0.586,0.547,0.547,0.576,0.576,0.469,0.469
#Zone 6
x_{10} = 0.447,0.508,0.508,0.586,0.586,0.676,0.676,0.586,0.586,0.508,0.508,0.447
y_{10} = 0.34,0.34,0.363,0.363,0.34,0.34,0.424,0.424,0.402,0.402,0.418,0.418
#Zone 3
x_{11} = 0.213,0.213,0.426,0.426,0.365,0.365,0.254,0.254,0.213
y_{11} = 0.295,0.295,0.295,0.42,0.42,0.342,0.342,0.389,0.389
#Zone 1
x_{12} = 0.326,0.326,0.461,0.461,0.447,0.447,0.342,0.342,0.314,0.314,0.279,0.279,0.266,0.266,0.293,0.293,0.326
y_{12} = 0.086,0.086,0.086,0.168,0.168,0.281,0.281,0.244,0.244,0.293,0.293,0.244,0.244,0.197,0.197,0.1,0.1
#Zone 2
x_{13} = 0.448,0.448,0.525,0.525,0.498,0.498,0.445,0.445,0.461,0.461,0.448
y_{13} = 0.169,0.169,0.169,0.232,0.232,0.338,0.338,0.303,0.303,0.244,0.244
#Zone 7
x_{14} = 0.529,0.529,0.592,0.592,0.643,0.643,0.668,0.668,0.559,0.559,0.58,0.58,0.529,0.463,0.463,0.529
y_{14} = 0.086,0.086,0.086,0.154,0.24,0.264,0.264,0.338,0.338,0.295,0.295,0.242,0.154,0.154,0.115,0.115
#Zone 4
x_{15} = 0.135,0.135,0.258,0.258,0.213,0.213,0.135
y_{15} = 0.467,0.467,0.467,0.666,0.666,0.553,0.553
`)

const ASCENT_ZONES = {
  'A Alley': {
    id: 68,
    name: 'A Alley',
    poly: [
      [0.815, 0.351],
      [0.64, 0.351],
      [0.64, 0.316],
      [0.745, 0.316],
      [0.745, 0.302],
      [0.815, 0.302],
      [0.815, 0.351],
    ],
  },
  'A Boxes': {
    id: 119,
    name: 'A Boxes',
    poly: [
      [0.269, 0.17],
      [0.354, 0.17],
      [0.354, 0.078],
      [0.269, 0.078],
      [0.269, 0.17],
    ],
  },
  'A Connector': {
    id: 85,
    name: 'A Connector',
    poly: [
      [0.535, 0.636],
      [0.507, 0.636],
      [0.507, 0.63],
      [0.486, 0.63],
      [0.486, 0.556],
      [0.513, 0.556],
      [0.513, 0.554],
      [0.535, 0.554],
      [0.535, 0.557],
      [0.564, 0.557],
      [0.564, 0.61],
      [0.535, 0.61],
      [0.535, 0.636],
    ],
  },
  'A Door': {
    id: 117,
    name: 'A Door',
    poly: [
      [0.381, 0.239],
      [0.409, 0.239],
      [0.409, 0.231],
      [0.431, 0.231],
      [0.431, 0.219],
      [0.359, 0.219],
      [0.359, 0.231],
      [0.381, 0.231],
      [0.381, 0.239],
    ],
  },
  'A Lobby': {
    id: 73,
    name: 'A Lobby',
    poly: [
      [0.67, 0.315],
      [0.64, 0.315],
      [0.64, 0.302],
      [0.514, 0.302],
      [0.514, 0.278],
      [0.514, 0.25],
      [0.611, 0.25],
      [0.611, 0.229],
      [0.67, 0.229],
      [0.67, 0.315],
    ],
  },
  'A Long': {
    id: 78,
    name: 'A Long',
    poly: [
      [0.508, 0.251],
      [0.464, 0.251],
      [0.464, 0.094],
      [0.493, 0.094],
      [0.493, 0.113],
      [0.508, 0.113],
      [0.508, 0.251],
    ],
  },
  'A Near': {
    id: 120,
    name: 'A Near',
    poly: [
      [0.354, 0.219],
      [0.431, 0.219],
      [0.431, 0.078],
      [0.354, 0.078],
      [0.354, 0.219],
    ],
  },
  'A Orb': {
    id: 77,
    name: 'A Orb',
    poly: [
      [0.464, 0.251],
      [0.464, 0.288],
      [0.507, 0.288],
      [0.507, 0.278],
      [0.515, 0.278],
      [0.515, 0.251],
      [0.464, 0.251],
    ],
  },
  'Arch': {
    id: 81,
    name: 'Arch',
    poly: [
      [0.431, 0.166],
      [0.431, 0.13],
      [0.465, 0.13],
      [0.465, 0.166],
      [0.431, 0.166],
    ],
  },
  'A Short': {
    id: 93,
    name: 'A Short',
    poly: [
      [0.472, 0.397],
      [0.438, 0.397],
      [0.438, 0.309],
      [0.493, 0.309],
      [0.493, 0.365],
      [0.472, 0.365],
      [0.472, 0.397],
    ],
  },
  'Attacker Spawn': {
    id: 62,
    name: 'Attacker Spawn',
    poly: [
      [0.724, 0.636],
      [0.86, 0.636],
      [0.86, 0.615],
      [0.966, 0.615],
      [0.966, 0.577],
      [0.941, 0.555],
      [0.935, 0.555],
      [0.935, 0.519],
      [0.835, 0.519],
      [0.835, 0.358],
      [0.815, 0.358],
      [0.815, 0.351],
      [0.744, 0.351],
      [0.744, 0.463],
      [0.717, 0.463],
      [0.717, 0.539],
      [0.724, 0.539],
      [0.724, 0.636],
    ],
  },
  'B': {
    id: 102,
    name: 'B',
    poly: [
      [0.206, 0.854],
      [0.336, 0.854],
      [0.336, 0.733],
      [0.206, 0.733],
      [0.206, 0.854],
    ],
  },
  'B Alley': {
    id: 66,
    name: 'B Alley',
    poly: [
      [0.86, 0.678],
      [0.86, 0.636],
      [0.507, 0.636],
      [0.507, 0.678],
      [0.86, 0.678],
    ],
  },
  'B Backsite': {
    id: 101,
    name: 'B Backsite',
    poly: [
      [0.22, 0.915],
      [0.322, 0.915],
      [0.322, 0.857],
      [0.31, 0.857],
      [0.31, 0.854],
      [0.293, 0.854],
      [0.293, 0.858],
      [0.248, 0.858],
      [0.248, 0.854],
      [0.234, 0.854],
      [0.234, 0.857],
      [0.22, 0.857],
      [0.22, 0.915],
    ],
  },
  'B Door': {
    id: 107,
    name: 'B Door',
    poly: [
      [0.268, 0.636],
      [0.316, 0.636],
      [0.316, 0.602],
      [0.299, 0.602],
      [0.299, 0.596],
      [0.276, 0.596],
      [0.276, 0.602],
      [0.268, 0.602],
      [0.268, 0.636],
    ],
  },
  'B Lane': {
    id: 100,
    name: 'B Lane',
    poly: [
      [0.336, 0.854],
      [0.336, 0.759],
      [0.339, 0.76],
      [0.339, 0.672],
      [0.367, 0.672],
      [0.367, 0.854],
      [0.336, 0.854],
    ],
  },
  'B Lobby': {
    id: 95,
    name: 'B Lobby',
    poly: [
      [0.486, 0.782],
      [0.627, 0.782],
      [0.627, 0.678],
      [0.506, 0.678],
      [0.506, 0.67],
      [0.486, 0.67],
      [0.486, 0.782],
    ],
  },
  'B Main': {
    id: 96,
    name: 'B Main',
    poly: [
      [0.486, 0.769],
      [0.482, 0.769],
      [0.482, 0.776],
      [0.373, 0.776],
      [0.373, 0.615],
      [0.445, 0.615],
      [0.445, 0.692],
      [0.423, 0.692],
      [0.423, 0.733],
      [0.482, 0.733],
      [0.482, 0.741],
      [0.486, 0.741],
      [0.486, 0.769],
    ],
  },
  'B Near': {
    id: 108,
    name: 'B Near',
    poly: [
      [0.206, 0.672],
      [0.206, 0.636],
      [0.247, 0.636],
      [0.247, 0.602],
      [0.267, 0.602],
      [0.267, 0.637],
      [0.316, 0.637],
      [0.317, 0.623],
      [0.337, 0.623],
      [0.337, 0.644],
      [0.373, 0.644],
      [0.373, 0.672],
      [0.339, 0.672],
      [0.339, 0.701],
      [0.237, 0.7],
      [0.237, 0.672],
      [0.206, 0.672],
    ],
  },
  'Boat': {
    id: 105,
    name: 'Boat',
    poly: [
      [0.367, 0.643],
      [0.367, 0.623],
      [0.337, 0.623],
      [0.337, 0.643],
      [0.367, 0.643],
    ],
  },
  'Bottom Mid': {
    id: 97,
    name: 'Bottom Mid',
    poly: [
      [0.373, 0.552],
      [0.434, 0.552],
      [0.434, 0.439],
      [0.373, 0.439],
      [0.373, 0.552],
    ],
  },
  'B Stairs': {
    id: 104,
    name: 'B Stairs',
    poly: [
      [0.206, 0.734],
      [0.235, 0.734],
      [0.235, 0.672],
      [0.206, 0.672],
      [0.206, 0.734],
    ],
  },
  'Catwalk': {
    id: 92,
    name: 'Catwalk',
    poly: [
      [0.437, 0.426],
      [0.64, 0.426],
      [0.64, 0.398],
      [0.437, 0.398],
      [0.437, 0.426],
    ],
  },
  'Cubby': {
    id: 76,
    name: 'Cubby',
    poly: [
      [0.443, 0.288],
      [0.465, 0.288],
      [0.465, 0.251],
      [0.443, 0.251],
      [0.443, 0.288],
    ],
  },
  'Defender Hall': {
    id: 109,
    name: 'Defender Hall',
    poly: [
      [0.206, 0.671],
      [0.2, 0.671],
      [0.2, 0.685],
      [0.145, 0.685],
      [0.145, 0.504],
      [0.2, 0.504],
      [0.2, 0.581],
      [0.179, 0.581],
      [0.179, 0.608],
      [0.2, 0.608],
      [0.2, 0.643],
      [0.206, 0.643],
      [0.206, 0.671],
    ],
  },
  'Defender Spawn': {
    id: 110,
    name: 'Defender Spawn',
    poly: [
      [0.04, 0.504],
      [0.04, 0.378],
      [0.2, 0.378],
      [0.2, 0.504],
      [0.04, 0.504],
    ],
  },
  'Deli': {
    id: 75,
    name: 'Deli',
    poly: [
      [0.514, 0.25],
      [0.514, 0.229],
      [0.611, 0.229],
      [0.611, 0.25],
      [0.514, 0.25],
    ],
  },
  'Garden': {
    id: 112,
    name: 'Garden',
    poly: [
      [0.367, 0.296],
      [0.36, 0.296],
      [0.36, 0.316],
      [0.304, 0.316],
      [0.304, 0.33],
      [0.27, 0.33],
      [0.27, 0.267],
      [0.291, 0.267],
      [0.313, 0.251],
      [0.36, 0.251],
      [0.36, 0.272],
      [0.367, 0.272],
      [0.367, 0.296],
    ],
  },
  'Gelato': {
    id: 71,
    name: 'Gelato',
    poly: [
      [0.669, 0.315],
      [0.701, 0.315],
      [0.701, 0.229],
      [0.669, 0.229],
      [0.669, 0.315],
    ],
  },
  'Generator': {
    id: 118,
    name: 'Generator',
    poly: [
      [0.269, 0.219],
      [0.354, 0.219],
      [0.354, 0.169],
      [0.269, 0.169],
      [0.269, 0.219],
    ],
  },
  'Heaven': {
    id: 116,
    name: 'Heaven',
    poly: [
      [0.228, 0.26],
      [0.263, 0.26],
      [0.263, 0.17],
      [0.268, 0.17],
      [0.268, 0.078],
      [0.228, 0.078],
      [0.228, 0.26],
    ],
  },
  'Heaven Hall': {
    id: 115,
    name: 'Heaven Hall',
    poly: [
      [0.228, 0.26],
      [0.228, 0.225],
      [0.143, 0.225],
      [0.143, 0.26],
      [0.228, 0.26],
    ],
  },
  'Library': {
    id: 82,
    name: 'Library',
    poly: [
      [0.64, 0.463],
      [0.64, 0.426],
      [0.542, 0.426],
      [0.542, 0.475],
      [0.569, 0.475],
      [0.583, 0.463],
      [0.64, 0.463],
    ],
  },
  'Lights': {
    id: 114,
    name: 'Lights',
    poly: [
      [0.305, 0.371],
      [0.179, 0.371],
      [0.179, 0.329],
      [0.305, 0.329],
      [0.305, 0.371],
    ],
  },
  'Logs': {
    id: 106,
    name: 'Logs',
    poly: [
      [0.337, 0.623],
      [0.316, 0.623],
      [0.316, 0.603],
      [0.337, 0.603],
      [0.337, 0.623],
    ],
  },
  'Market': {
    id: 99,
    name: 'Market',
    poly: [
      [0.262, 0.595],
      [0.348, 0.595],
      [0.348, 0.545],
      [0.323, 0.521],
      [0.31, 0.521],
      [0.31, 0.519],
      [0.277, 0.519],
      [0.277, 0.521],
      [0.262, 0.521],
      [0.262, 0.595],
    ],
  },
  'Mid': {
    id: 83,
    name: 'Mid',
    poly: [
      [0.569, 0.476],
      [0.569, 0.553],
      [0.434, 0.553],
      [0.434, 0.44],
      [0.437, 0.44],
      [0.437, 0.426],
      [0.508, 0.426],
      [0.542, 0.426],
      [0.542, 0.476],
      [0.569, 0.476],
    ],
  },
  'Mid Pizza': {
    id: 98,
    name: 'Mid Pizza',
    poly: [
      [0.373, 0.519],
      [0.373, 0.469],
      [0.333, 0.468],
      [0.333, 0.462],
      [0.333, 0.435],
      [0.282, 0.434],
      [0.283, 0.461],
      [0.269, 0.475],
      [0.256, 0.475],
      [0.243, 0.461],
      [0.243, 0.405],
      [0.202, 0.405],
      [0.202, 0.504],
      [0.268, 0.504],
      [0.268, 0.519],
      [0.373, 0.519],
    ],
  },
  'Ramp': {
    id: 111,
    name: 'Ramp',
    poly: [
      [0.143, 0.378],
      [0.179, 0.378],
      [0.179, 0.26],
      [0.143, 0.26],
      [0.143, 0.328],
      [0.13, 0.328],
      [0.13, 0.371],
      [0.143, 0.371],
      [0.143, 0.378],
    ],
  },
  'Top Mid': {
    id: 69,
    name: 'Top Mid',
    poly: [
      [0.639, 0.462],
      [0.688, 0.462],
      [0.688, 0.351],
      [0.64, 0.351],
      [0.64, 0.398],
      [0.639, 0.398],
      [0.639, 0.462],
    ],
  },
  'Tree': {
    id: 94,
    name: 'Tree',
    poly: [
      [0.366, 0.358],
      [0.425, 0.358],
      [0.425, 0.345],
      [0.437, 0.345],
      [0.437, 0.322],
      [0.425, 0.322],
      [0.425, 0.239],
      [0.366, 0.239],
      [0.366, 0.358],
    ],
  },
  'White Room': {
    id: 113,
    name: 'White Room',
    poly: [
      [0.228, 0.309],
      [0.266, 0.309],
      [0.266, 0.292],
      [0.27, 0.292],
      [0.27, 0.275],
      [0.266, 0.275],
      [0.266, 0.26],
      [0.228, 0.26],
      [0.228, 0.309],
    ],
  },
  'Wine': {
    id: 79,
    name: 'Wine',
    poly: [
      [0.507, 0.093],
      [0.464, 0.093],
      [0.464, 0.048],
      [0.507, 0.048],
      [0.507, 0.093],
    ],
  },
  'Workshop': {
    id: 103,
    name: 'Workshop',
    poly: [
      [0.238, 0.731],
      [0.238, 0.7],
      [0.336, 0.7],
      [0.336, 0.731],
      [0.238, 0.731],
    ],
  },
}

const SPLIT_ZONES = parseZone(`#B Alley
x_{1} = 0.1876,0.287,0.287,0.1876
y_{1} = 0.303,0.3028,0.1694,0.1694
#B Back
x_{2} = 0.228,0.343,0.343,0.228
y_{2} = 0.028,0.028,0.0915,0.0915
#B Rafters
x_{3} = 0.4057,0.4057,0.394,0.394,0.287,0.287
y_{3} = 0.2388,0.2562,0.2562,0.2878,0.2878,0.2388
#B Back Site
x_{4} = 0.287,0.287,0.343,0.343,0.3732,0.3732
y_{4} = 0.2388,0.0915,0.0915,0.028,0.028,0.2388
#B Front Site
x_{5} = 0.3732,0.445,0.445,0.4057,0.4057,0.3732
y_{5} = 0.028,0.028,0.253,0.253,0.2388,0.2388
#B Hell
x_{6} = 0.394,0.394,0.4057,0.4057,0.445,0.445,0.4654,0.4654
y_{6} = 0.2878,0.2562,0.2562,0.253,0.253,0.2245,0.2245,0.2875
#B Heaven
x_{7} = 0.327,0.327,0.534,0.534
y_{7} = 0.349,0.2878,0.2878,0.349
#Mid Mail
x_{8} = 0.43,0.43,0.5,0.5
y_{8} = 0.428,0.349,0.349,0.428
#Mid Top
x_{9} = 0.44,0.44,0.545,0.545
y_{9} = 0.507,0.428,0.428,0.507
#Mid Vent
x_{10} = 0.35,0.35,0.44,0.44
y_{10} = 0.59,0.445,0.445,0.59
#Mid Bottom
x_{11} = 0.545,0.545,0.681,0.681
y_{11} = 0.501,0.408,0.408,0.501
#B Main
x_{12} = 0.445,0.61,0.61,0.592,0.592,0.445
y_{12} = 0.06,0.06,0.14,0.14,0.224,0.224
#B Lobby
x_{13} = 0.592,0.592,0.813,0.813
y_{13} = 0.256,0.16,0.16,0.256
#B Link
x_{14} = 0.59,0.59,0.7,0.7
y_{14} = 0.408,0.256,0.256,0.408
#B Stairs
x_{15} = 0.28,0.28,0.351,0.351
y_{15} = 0.402,0.349,0.349,0.402
#Attacker Side Spawn
x_{16} = 0.68,0.68,0.715,0.715,0.93,0.93
y_{16} = 0.803,0.516,0.516,0.256,0.256,0.803
#A Sewers
x_{17} = 0.573,0.573,0.672,0.672,0.63,0.63
y_{17} = 0.818,0.5,0.5,0.79,0.79,0.818
#A Lobby
x_{18} = 0.529,0.637,0.637,0.738,0.738,0.529
y_{18} = 0.818,0.818,0.803,0.803,0.93,0.93
#A Main
x_{19} = 0.412,0.412,0.562,0.562,0.529,0.529
y_{19} = 0.873,0.74,0.74,0.818,0.818,0.873
#A Ramps
x_{20} = 0.381,0.381,0.55,0.55,0.473,0.473
y_{20} = 0.694,0.612,0.611,0.74,0.74,0.694
#A Rafters
x_{21} = 0.278,0.278,0.415,0.415
y_{21} = 0.74,0.71,0.71,0.74
#A Heaven
x_{22} = 0.217,0.217,0.344,0.344,0.381,0.381
y_{22} = 0.71,0.586,0.586,0.59,0.59,0.71
#A Elbow
x_{23} = 0.2,0.2,0.244,0.244
y_{23} = 0.9,0.806,0.806,0.9
#A Back Site
x_{24} = 0.23,0.23,0.44,0.44
y_{24} = 0.96,0.9,0.9,0.96
#A Hell
x_{25} = 0.28,0.28,0.412,0.412
y_{25} = 0.756,0.74,0.74,0.756
#A Site
x_{26} = 0.244,0.244,0.185,0.185,0.28,0.28,0.412,0.412
y_{26} = 0.9,0.806,0.806,0.747,0.747,0.756,0.756,0.9
#A Screens
x_{27} = 0.125,0.125,0.185,0.185
y_{27} = 0.845,0.717,0.717,0.845
#A Alley
x_{28} = 0.125,0.1249,0.217,0.217
y_{28} = 0.717,0.512,0.512,0.717
#Defender Side Spawn
x_{29} = 0.05,0.05,0.28,0.28,0.305,0.305
y_{29} = 0.512,0.303,0.303,0.43,0.43,0.512`)

function parseZone(zones) {
  let key
  return Object.freeze(
    Object.fromEntries(
      Object.entries(
        zones
          .split('\n')
          .map(l => l.trim())
          .filter(Boolean)
          .reduce((zones, line, idx) => {
            if (line.startsWith('#')) {
              key = line.substring(1).trim() || Math.round(idx / 3)
              zones[key] = { name: key }
              return zones
            }
            key = key || Math.floor(idx / 3)
            zones[key] = {
              ...zones[key],
              [line.substring(0, 1)]: line
                .substring(line.indexOf('=') + 1)
                .split(',')
                .map(x => parseFloat(x.trim())),
            }
            return zones
          }, {})
      )
        .filter(([, zone]) => zone.x?.length && zone.y?.length && zone.x.length === zone.y.length)
        .map(([key, zone]) => [
          key,
          Object.freeze({
            id: key,
            name: zone.name,
            poly: Array.from({ length: zone.x.length }, (_, i) => [zone.x[i], zone.y[i]]),
          }),
        ])
    )
  )
}

export const getZones = mapId => {
  if (process.env.VUE_APP_ENABLE_ZONES !== 'yes') {
    return
  }
  switch (mapId) {
    case '7eaecc1b-4337-bbf6-6ab9-04b8f06b3319':
      return ASCENT_ZONES
    case 'd960549e-485c-e861-8d71-aa9d1aed12a2':
      return SPLIT_ZONES
    case '2bee0dc9-4ffe-519b-1cbd-7fbe763a6047':
      return HAVEN_ZONES
  }
}

/**
 * @param zones
 * @param {API_LOCATION} location
 * @returns {string}
 */
export const getPointZone = (zones, location) =>
  location?.x != null && location?.y != null
    ? Object.entries(zones || {}).find(([, zone]) => polygonContains(zone.poly, [location.x, location.y]))?.[0]
    : undefined
