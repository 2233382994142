import * as Sentry from '@sentry/vue'

import axios from '@/axios.js'

import { getAssets, getTeamAbbrs } from '../../api/static.js'
import ValorantMaps from '../../assets/valorant-maps.json'
import genAbilityHashId from '../../components/Map/utils/genAbilityHashId.js'
import stringCompare from '../../utils/stringCompare.js'

const { freeze, fromEntries, values } = Object

const compareByName = stringCompare(a => a.name)

// eslint-disable-next-line no-unused-vars
const AGENTS_ABILITIES_ORDER = ['passive', 'grenade', 'ability1', 'ability2', 'ultimate']

export default {
  namespaced: true,

  state: {
    assets: null,
    teamAbbrs: {},
    error: null,
    loading: false,
  },

  mutations: {
    CLEAR_ERROR: state => {
      state.error = null
    },
    SET_ASSETS: (state, assets) => {
      state.assets = freeze(assets)
    },
    SET_TEAM_ABBRS: (state, assets) => {
      state.teamAbbrs = freeze(assets)
    },
    SET_ERROR: (state, error) => {
      state.error = error
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading
    },
  },

  getters: {
    error: state => state.error,
    has_assets: state => !state.error && !state.loading && !!state.assets,
    loading: state => state.loading,

    _assets: (state, { loading, error, has_assets }) => {
      if (loading) {
        throw new Error(
          "Assets are still loading, ensure you await the call dispatch('static/get_assets') before using the assets"
        )
      }
      if (error) {
        throw new Error(`There was an error loading assets: ${error}`)
      }
      if (!has_assets) {
        throw new Error("Assets are not loaded! Call and await dispatch('static/get_assets') to load them")
      }
      return state.assets
    },

    // prepare abilities
    _abilities: (state, { _agents }) =>
      values(
        fromEntries(
          _agents
            .flatMap(agent =>
              agent.abilities.map(ability =>
                freeze({
                  ...ability,
                  id: genAbilityHashId(agent, ability.slot),
                  name: ability.displayName,
                  icon: ability.displayIcon,
                  // lower case only, dashes to separate words, dashes instead of special characters
                  gridName: ability.displayName.replace(/[^a-zA-Z0-9]/, '-').toLowerCase(),
                })
              )
            )
            .map(ability => [ability.id, ability])
        )
      ),
    abilitiesById: (state, { _abilities }) => fromEntries(_abilities.map(ability => [ability.id, ability])),
    abilitiesByName: (state, { _abilities }) => fromEntries(_abilities.map(ability => [ability.name, ability])),
    abilitiesByGridName: (state, { _abilities }) => fromEntries(_abilities.map(ability => [ability.gridName, ability])),
    abilitiesList: (state, { _abilities }) => [..._abilities].sort(compareByName),
    getAbilityById:
      (state, { abilitiesById }) =>
      id =>
        abilitiesById[id],
    getAbilityByName:
      (state, { abilitiesByName }) =>
      name =>
        abilitiesByName[name],
    getAbilityByAgentSlot:
      (state, { getAbilityById, getAgentById, getAgentByName }) =>
      (agent, slot) => {
        if (typeof agent === 'string') {
          agent = getAgentById(agent) ?? getAgentByName(agent)
        } else if (agent.id) {
          agent = getAgentById(agent.id)
        } else {
          throw new Error(`Unsupported agent type ${typeof agent}`)
        }
        return getAbilityById(genAbilityHashId(agent, slot))
      },

    // clean up agents
    _agents: (state, { _assets }) =>
      values(
        fromEntries(
          values(_assets.agents)
            .map(agentId => {
              const agent = _assets.agents_expand.find(agent => agent.id === agentId)
              const abilities = agent.abilities.map(ability => ({
                ...ability,
                order: AGENTS_ABILITIES_ORDER.indexOf(ability.slot.toLowerCase()),
              }))
              return {
                ...agent,
                abilities: Object.assign(
                  abilities,
                  Object.fromEntries(abilities.map(ability => [ability.slot, ability]))
                ),
              }
            })
            // freeze and convert id to lowercase
            .map(agent =>
              freeze({
                ...agent,
                id: agent.id.toLowerCase(),
              })
            )
            // remove second Sova
            .filter(agent => agent.id !== 'ded3520f-4264-bfed-162d-b080e2abccf9')
            .map(agent => [agent.id, agent])
        )
      ),
    agentsById: (state, { _agents }) => fromEntries(_agents.map(agent => [agent.id, agent])),
    agentsByName: (state, { _agents }) => fromEntries(_agents.map(agent => [agent.name, agent])),
    agentsByNameLowercase: (state, { _agents }) => fromEntries(_agents.map(agent => [agent.name.toLowerCase(), agent])),
    agentsByModelName: (state, { _agents }) => fromEntries(_agents.map(agent => [agent.mlname, agent])),
    agentsList: (state, { _agents }) => _agents.sort(compareByName),
    agents: (state, { agentsList }) => agentsList,
    getAgentById:
      (state, { agentsById }) =>
      id =>
        agentsById[id],
    getAgentByName:
      (state, { agentsByName }) =>
      name =>
        agentsByName[name],

    // clean up maps
    _maps: (state, { _assets }) =>
      values(
        fromEntries(
          values(_assets.maps)
            // add bombsites
            .map(mapId => {
              const map = _assets.maps_expand.find(mapExpanded => mapExpanded.id === mapId)
              return {
                ...ValorantMaps.data.find(m => m.mapUrl === map.url),
                ...map,
                bombsites: ['Haven', 'Lotus'].includes(map.name) ? ['A', 'B', 'C'] : ['A', 'B'],
              }
            })
            // freeze and convert id to lowercase
            .map(map =>
              freeze({
                ...map,
                id: map.id.toLowerCase(),
              })
            )
            // remove The Range and Team Deathmatch maps
            .filter(map => map.id !== 'ee613ee9-28b7-4beb-9666-08db13bb2244' && !map.url.includes('/HURM/'))
            .map(map => [map.id, map])
        )
      ),
    mapsById: (state, { _maps }) => fromEntries(_maps.map(map => [map.id, map])),
    mapsByNameLowecase: (state, { _maps }) => fromEntries(_maps.map(map => [map.name.toLowerCase(), map])),
    mapsByName: (state, { _maps }) => fromEntries(_maps.map(map => [map.name, map])),
    mapsByAnything: (state, { _maps }) =>
      fromEntries(
        _maps.flatMap(map => [
          [map.name, map],
          [map.name.toLowerCase(), map],
          [map.id, map],
          [map.id.toLowerCase(), map],
          [map.id.toUpperCase(), map],
          [map.url, map],
          [map.url.split('/').pop(), map],
          [map.url.split('/').pop().toLowerCase(), map],
        ])
      ),
    mapsByUrl: (state, { _maps }) => fromEntries(_maps.map(map => [map.url, map])),
    mapsList: (state, { _maps }) => _maps.sort(compareByName),
    maps: (state, { mapsList }) => mapsList,
    getMapById:
      (state, { mapsById }) =>
      id =>
        mapsById[id?.toLowerCase()],
    getMapByName:
      (state, { mapsByName }) =>
      name =>
        mapsByName[name],

    // clean up weapons
    _weapons: (state, { _assets, getAgentByName }) => {
      const weapons = [..._assets.weapons_expand]

      // add chamber abilities that are used as weapons
      const chamber = getAgentByName('Chamber')
      if (chamber) {
        const ability1 = chamber.abilities.find(({ slot }) => slot === 'Ability1')
        if (ability1) {
          weapons.push({
            display_icon_url: ability1.displayIcon,
            id: '856d9a7e-4b06-dc37-15dc-9d809c37cb90',
            kill_stream_icon_url: ability1.displayIcon,
            name: ability1.displayName,
          })
        }

        const ultimate = chamber.abilities.find(({ slot }) => slot === 'Ultimate')
        if (ultimate) {
          weapons.push({
            display_icon_url: ultimate.displayIcon,
            id: '39099fb5-4293-def4-1e09-2e9080ce7456',
            kill_stream_icon_url: ultimate.displayIcon,
            name: ultimate.displayName,
          })
        }
      }

      // add neon abilities that are used as weapons
      const neon = getAgentByName('Neon')
      if (neon) {
        const ultimate = neon.abilities.find(({ slot }) => slot === 'Ultimate')
        if (ultimate) {
          weapons.push({
            display_icon_url: ultimate.displayIcon,
            id: '95336AE4-45D4-1032-CFAF-6BAD01910607',
            kill_stream_icon_url: ultimate.displayIcon,
            name: ultimate.displayName,
          })
        }
      }

      return values(
        fromEntries(
          weapons
            // freeze and convert id to lowercase
            .map(weapon =>
              freeze({
                ...weapon,
                id: weapon.id.toLowerCase(),
              })
            )
            .map(weapon => [weapon.id, weapon])
        )
      )
    },
    weaponsById: (state, { _weapons }) => fromEntries(_weapons.map(weapon => [weapon.id, weapon])),
    weaponsByName: (state, { _weapons }) => fromEntries(_weapons.map(weapon => [weapon.name, weapon])),
    weaponsByNameLowercase: (state, { _weapons }) =>
      fromEntries(_weapons.map(weapon => [weapon.name.toLowerCase(), weapon])),
    weaponsList: (state, { _weapons }) => _weapons.sort(compareByName),
    weapons: (state, { weaponsById }) => weaponsById,
    getWeaponById:
      (state, { weaponsById }) =>
      id =>
        weaponsById[id?.toLowerCase()],
    getWeaponByName:
      (state, { weaponsByName }) =>
      name =>
        weaponsByName[name],
    // GEARS
    // clean up geаrs
    _gears: (state, { _assets }) => {
      return values(
        fromEntries(
          _assets.gears_expand
            // freeze and convert id to lowercase
            .map(gear =>
              freeze({
                ...gear,
                id: gear.id.toLowerCase(),
              })
            )
            .map(gear => [gear.id, gear])
        )
      )
    },
    gearsById: (state, { _gears }) => fromEntries(_gears.map(gear => [gear.id, gear])),
    gearsByName: (state, { _gears }) => fromEntries(_gears.map(gear => [gear.name, gear])),
    gearsList: (state, { _gears }) => _gears.sort(compareByName),
    gears: (state, { gearsById }) => gearsById,
    getGearById:
      (state, { gearsById }) =>
      id =>
        gearsById[id?.toLowerCase()],
    getGearByName:
      (state, { gearsByName }) =>
      name =>
        gearsByName[name],

    getTeamAbbrByName: state => name => state.teamAbbrs[name.toLowerCase()],
    getTeamAbbrById: state => id => state.teamAbbrs[id],
  },

  actions: {
    async get_assets({ commit }) {
      console.info('%c static/get_assets()', 'color:#38bfb2')

      commit('SET_LOADING', true)
      try {
        commit('CLEAR_ERROR')

        const data = await getAssets()
        commit('SET_ASSETS', data)
        const teamAbbrs = await getTeamAbbrs()
        commit('SET_TEAM_ABBRS', teamAbbrs)

        return true
      } catch (error) {
        const errorMsg = axios.extractErrorMessage(error)
        console.error('Failure static/get_assets()', errorMsg, error)
        commit('SET_ERROR', errorMsg)
        Sentry.captureException(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
